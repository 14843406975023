import React, { useState, useEffect } from 'react';
import { View, StyleSheet, FlatList, Pressable, Dimensions } from 'react-native';
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog, NewsDetailDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams } from '../reducers/parameterSlice';
import { Label } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { Paper, Card, CardActionArea, CardHeader, CardMedia, CardContent } from '@mui/material';

const News = (props) => {
    const clientParams = useSelector(parameters);
    const isMounted = useIsMounted();

    let initProgressState = {
        showProgress: false,
        showError: false,
        message: "Memuat Data"
    }

    const [progressState, setProgressState] = useState(initProgressState)
    const [initData, setInitData] = useState(props.data);
    const [newsData, setNewsData] = useState([]);
    const [newsItem, setNewsItem] = useState({ show: false, image: "" });

    useEffect(() => {
        if (!initData.loaded) {
            let progress = { ...progressState };
            progress.showProgress = true;
            progress.message = "Memuat Data";
            if (isMounted()) {
                setProgressState(progress);
                loadNewsData();
            }
        }

        if (initData.loaded) {
            renderNews();
        }

    }, [initData.loaded]);


    const loadNewsData = async () => {
        let formData = new FormData();
        formData.append("page", "1");
        formData.append("limit", "100");
        const clientID = global.headers.CLIENTID;
        const response = await executePost(clientID == 537 ? "https://kubuku.id/api/wl/candil/news" : api.news, formData);
        let progress = { ...progressState };
        progress.showProgress = false;
        if (isMounted()) {
            if (response.code == resCode.ok) {
                let data = {
                    loaded: true,
                    data: response.data
                }
                setInitData(data);
                setProgressState(progress);
                props.callback(data);

            } else {
                if (response.code != resCode.error) {
                    progress.showError = true;
                    progress.message = response.msg;
                    setProgressState(progress)
                } else {
                    //ANCHOR - Force Logout
                    window.postMessage({ command: "FORCE_LOGOUT" }, "*");

                }
            }
        }
    }

    const renderNews = () => {
        let data = [...initData.data];
        let mod = data.length % 4;
        if (mod > 0) {
            let add_factor = 4 - mod;
            for (let i = 0; i < add_factor; i++) {
                data.push({ dummy: i })
            }
        }
        setNewsData(data);
    }

    const onNewsClick = (item) => {
        let news_item = {
            show: true,
            image: item.pic_header,
            title: item.title,
            content: item.news,
            date: item.tgl
        }

        if (isMounted()) {
            setNewsItem(news_item);
        }
    }

    const renderItem = ({ item, index, separators }) => {
        if (!item.hasOwnProperty("dummy")) {
            return (
                <Paper
                    elevation={2}
                    style={{
                        borderRadius: 8,
                        flex: 1,
                        margin: 8
                    }}
                >
                    <Pressable
                        style={{
                            cursor: "pointer"
                        }}
                        onPress={() => { onNewsClick(item) }}
                    >
                        <img
                            src={item.pic_header}
                            style={{
                                maxHeight: 140,
                                borderRadius: 8
                            }}
                        />
                        <Label
                            size={12}
                            weight={"600"}
                            text={item.title}
                            style={{
                                marginTop: 8,
                                marginLeft: 8,
                                marginRight: 8,
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                overflow: "hidden",
                                textOverflow: "ellipsis"

                            }}
                        />
                        <Label
                            size={10}
                            weight={"400"}
                            color={"#95a5a6"}
                            text={item.tgl}
                            style={{ marginLeft: 8, marginBottom: 8 }}
                        />
                    </Pressable>

                </Paper>
            )

        } else {
            return (
                <View style={{ flex: 1, margin: 8 }}></View>
            )
        }
    }

    return (
        <View style={{ flex: 1 }}>
            {
                progressState.showProgress &&
                <Progress
                    text={progressState.message}
                />
            }
            {
                (!progressState.showProgress && newsData.length > 0) &&
                <FlatList
                    keyExtractor={(item, index) => "news_index_" + index}
                    data={newsData}
                    renderItem={renderItem}
                    numColumns={4}
                    showsVerticalScrollIndicator={false}
                />
            }
            <ErrorDialog
                open={progressState.showError}
                message={progressState.message}
                label={"OK"}
                onClick={() => {
                    let progress = { ...progressState }
                    progress.showError = false;
                    setProgressState(progress);
                }}
            />
            <NewsDetailDialog
                open={newsItem.show}
                height={Dimensions.get("window").width - 32}
                image={newsItem.image}
                date={newsItem.date}
                title={newsItem.title}
                content={newsItem.content}
                onClose={() => {
                    if (isMounted()) {
                        let item = { ...newsItem }
                        item.show = false;
                        setNewsItem(item);
                    }
                }}
            />
        </View>
    )
}


export { News }