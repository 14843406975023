//ANCHOR - First Page
import React, { Component , useState , useRef, useEffect, useImperativeHandle } from 'react'; 
import { View, StyleSheet, ScrollView } from 'react-native'; 
import { Modal, Box , DialogContent, CircularProgress, Typography, Snackbar } from '@mui/material'; 
import Alert from '@mui/material/Alert';
import { Label, PushButton, TextField } from './FormComponents';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IconButton, Divider } from '@mui/material';
import { parameters } from '../reducers/parameterSlice';
import { useSelector } from 'react-redux'
import { ErrorMark, SuccessMark, QuestionMark } from '../assets/Placeholders';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { executePost, executeGet , resCode, api } from '../utilities/Network';


const Progress = (props)=>{
    return(
        <View style={ styles.container}>
            <CircularProgress
            />
            <Label
                size={14}
                text={props.text}
                weight={"400"}
                textAlign={"center"}
                style={{
                    marginTop : 16
                }}
            />
        </View>
    )
}

const SuccessPage = (props)=>{
    return(
        <View style={ styles.container}>
            <SuccessMark 
                width={ 128 }
                height={ 128 }
            />
            <Label
                size={14}
                text={props.text}
                weight={"400"}
                textAlign={"center"}
                style={{
                    marginTop : 16
                }}
            />
            <PushButton
                variant={"contained"}
                text={"OK"}
                style={{ marginTop : 12 }}
                onClick={ props.onClick}
            />

        </View>
    )
}

const ErrorPage = (props)=>{
    return(
        <View style={ styles.container}>
            <ErrorMark 
                width={ 128 }
                height={ 128 }
            />
            <Label
                size={14}
                text={props.text}
                weight={"400"}
                textAlign={"center"}
                style={{
                    marginTop : 16
                }}
            />
            <PushButton
                variant={"contained"}
                text={"OK"}
                style={{ marginTop : 12 }}
                onClick={ props.onClick}
            />

        </View>
    )
}

const ProgressDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    return(
        <ThemeProvider theme={theme}>
            <Modal
                open={ props.open }
            >
                <Box tabIndex={2}>
                    <View style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 250,
                        height : 250, 
                        backgroundColor: 'white',
                        boxShadow: 24,
                        borderRadius : 15, 
                        alignItems : "center", 
                        justifyContent : "center"
                    }}>
                        <CircularProgress 
                            size={ 64 }
                        />
                        <Label 
                            size={14}
                            weight={"600"}
                            textAlign={"center"}
                            style={{marginTop : 16, marginLeft : 8 , marginRight : 8 }}
                            text={ props.message}
                        />
                    </View>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}


const ErrorDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    return(
        <ThemeProvider theme={theme}>
             <Modal
                open={ props.open }
            >
                <Box>
                    <View style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 250,
                        height : 250, 
                        backgroundColor: 'white',
                        boxShadow: 24,
                        borderRadius : 15, 
                        alignItems : "center", 
                        justifyContent : "center"
                    }}>
                        <ErrorMark 
                            width={ 64 }
                            height={ 64 }
                        />

                        <Label
                            size={12}
                            weight={"400"}
                            color={"#d63031"}
                            style={{marginTop : 8, marginLeft : 12, marginRight : 12 }}
                            text={props.message }
                            textAlign={ "center" }
                        />
                        <PushButton 
                            variant={"outlined"}
                            style={{
                                position : "absolute", 
                                borderRadius : 8, 
                                bottom : 12
                            }}
                            text={ props.label }
                            onClick={ props.onClick }
                        />
                    </View>
                </Box>
            </Modal>

        </ThemeProvider>
    )

}
//ANCHOR - Success Dialog
const SuccessDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });
    return(
        <ThemeProvider theme={theme}>
             <Modal
                open={ props.open }
            >
                <Box>
                    <View style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 250,
                        height : 250, 
                        backgroundColor: 'white',
                        boxShadow: 24,
                        borderRadius : 15, 
                        alignItems : "center", 
                        justifyContent : "center"
                    }}>
                        <SuccessMark 
                            width={ 64 }
                            height={ 64 }
                        />

                        <Label
                            size={14}
                            weight={"600"}
                            color={ clientParams.warna_header }
                            style={{marginTop : 8, marginLeft : 12, marginRight : 12 }}
                            text={props.message }
                            textAlign={ "center" }
                        />
                        <PushButton 
                            variant={"outlined"}
                            style={{
                                position : "absolute", 
                                borderRadius : 8, 
                                bottom : 12
                            }}
                            text={ props.label }
                            onClick={ props.onClick }
                        />
                    </View>
                </Box>
            </Modal>

        </ThemeProvider>
    )
}


const ConfirmDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });
    return(
        <ThemeProvider theme={theme}>
             <Modal
                open={ props.open }
            >
                <Box>
                    <View style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 350,
                        height : 350, 
                        backgroundColor: 'white',
                        boxShadow: 24,
                        borderRadius : 15, 
                        alignItems : "center"
                    }}>
                        <View style={{marginTop : 64 }}>
                            <QuestionMark 
                                fill={"#2980b9"}
                                width={ 128 }
                                height={ 128 }
                            />
                        </View>

                        <Label
                            size={14}
                            weight={"600"}
                            color={ clientParams.warna_header }
                            style={{marginTop : 8, marginLeft : 12, marginRight : 12 }}
                            text={props.message }
                            textAlign={ "center" }
                        />
                        <View style={{ 
                            position : "absolute", 
                            bottom : 16, 
                            right : 0, 
                            left : 0 , 
                            flexDirection : "row-reverse",
                            width : 350
                        }}>
                            <PushButton 
                                variant={"contained"}
                                style={{
                                    borderRadius : 8, 
                                    marginRight : 16,
                                }}
                                text={ props.labelPositive }
                                onClick={ props.onPositiveClick }
                            />
                            <PushButton 
                                variant={"outlined"}
                                style={{
                                    borderRadius : 8, 
                                    marginRight : 16,
                                }}
                                text={ props.labelNegative }
                                onClick={ props.onNegativeClick }
                            />
                        </View>
                    </View>
                </Box>
            </Modal>

        </ThemeProvider>
    )
}
const ForgotPasswordDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    const [email, setEmail ] = useState(""); 
    const [errorEmail, setErrorEmail ] = useState(false);

    const confirmClick = ()=>{
        let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if(email == "" || !regEmail.test(email)){
            setErrorEmail(true);
            return;
        }

        props.onConfirmClick(email);
    }


    return(
        <ThemeProvider theme={theme}>
            <Modal
                open={ props.open }
            >
                <Box>
                    <View style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 350,
                        height : 300, 
                        backgroundColor: 'white',
                        boxShadow: 24,
                        borderRadius : 15, 
                        
                    }}>

                    <Label
                        size={14}
                        weight={"600"}
                        text={"Lupa Password?"}
                        style={{
                            marginTop : 16,
                            marginLeft : 16
                        }}
                    />
                    <Label
                        size={12}
                        weight={"400"}
                        text={"Masukkan email anda yang terdaftar, sistem akan mengirimkan langkah selanjutnya"}
                        style={{
                            marginTop : 8, 
                            marginLeft : 16
                        }}
                    />
                    <View 
                        style={{
                            height : 0.3,
                            backgroundColor : "silver", 
                            width : "100%",
                            marginTop : 12
                        }}
                    />
                        <TextField 
                            labelText="E-mail"
                            marginLeft={16}
                            marginRight={16}
                            marginTop={12}
                            onChange={(text)=>{ 
                                setEmail(text);
                                setErrorEmail(false);
                            }}
                            placeholder={"Masukkan e-mail"}
                            error={ errorEmail }
                            errorText={"Harap masukkan e-mail anda"}
                        />


                        <View style={{
                            position : "absolute", 
                            bottom : 12, 
                            right : 16, 
                            flexDirection : "row-reverse"
                        }}>
                            
                            <PushButton 
                                variant={"contained"}
                                style={{ 
                                    borderRadius : 8, 
                                }}
                                text={ "OK" }
                                onClick={ confirmClick }
                            />

                            <PushButton 
                                variant={"outlined"}
                                style={{ 
                                    borderRadius : 8, 
                                    marginRight : 8
                                }}
                                text={ "Batal" }
                                onClick={ props.onCancelClick }
                            />


                    
                        </View>
                    </View>
                </Box>
            </Modal>
        </ThemeProvider>
    )
}

const NewsDetailDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    return(
        <ThemeProvider theme={theme}>
            <Modal
                open={ props.open }
                disableEscapeKeyDown={true}
                onClose={ props.onClose }
            >
                <Box>
                    <View style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 450,
                        height : "98%", 
                        backgroundColor: 'white',
                        boxShadow: 24,
                        borderRadius : 15, 
                        
                    }}>
                        <img 
                            src={props.image}
                            style={{
                                borderRadius : 15,
                            }}
                        />
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                        >
                            <Label
                                size={10}
                                color={"#95a5a6"}
                                text={props.date}
                                weight={"400"}
                                style={{marginLeft : 16, marginTop : 12}}
                            />
                            <Label 
                                size={12}
                                weight={"600"} 
                                text={props.title}
                                style={{marginLeft : 16, marginTop : 8, marginRight : 16, marginBottom : 12 }}
                            />
                            <Typography
                                fontFamily={"Poppins"}
                                fontSize={12}
                                fontWeight={"400"}
                                component={"span"}
                                style={{
                                    marginLeft : 16, 
                                    marginRight : 16
                                }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{ __html: props.content}}
                                />

                            </Typography>
                        </ScrollView>
                    </View>
                </Box>
            </Modal>


        </ThemeProvider>
    )

}

const BookInfoDialog = (props)=>{

    const [open, setOpen ]  = useState(false);
    const handleClose = (event, reason)=>{
        if (reason === 'clickaway') {
            return; 
        }
        setOpen(false);
    }
    const onCopyClick = (which)=>{
        if(which === "MLA"){
            navigator.clipboard.writeText(props.data.mla_style);
        } else if(which === "CHG") {
            navigator.clipboard.writeText(props.data.chicago_style);
        } else if(which === "TRB"){
            navigator.clipboard.writeText(props.data.turabian_style);
        } else if(which === "HRV") {
            navigator.clipboard.writeText(props.data.harvard_style);
        } else if(which === "APA") {
            navigator.clipboard.writeText(props.data.apa_style);
        } else if(which === "IEE") {
            navigator.clipboard.writeText(props.data.ieee_style);
        }
        
        setOpen(true);
    }


    return(
        <Modal
            open={ props.open }
            disableEscapeKeyDown={true}
            onClose={ props.onClose }
        >
            <Box>
                <View style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: props.width,
                    height : props.height, 
                    backgroundColor: 'white',
                    boxShadow: 24,
                    borderRadius : 15, 
                    
                }}>
                    <Label
                        size={16}
                        text={"Info Buku"}
                        weight={"600"}
                        textAlign={"left"}
                        style={{
                            marginTop : 16,
                            marginLeft : 16,
                            marginBottom : 8
                        }}
                    />
                    <View style={{ height : 0.3, marginLeft : 16, marginRight : 16, backgroundColor : "#2d3436"}} /> 
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"Judul"}
                            weight={"400"} 
                            style={{flex : 0.2}}
                        />
                        <Label
                            size={14}
                            text={props.data.judul} 
                            weight={"600"}
                            style={{flex : 0.8}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"Penulis"}
                            weight={"400"} 
                            style={{flex : 0.2}}
                        />
                        <Label
                            size={14}
                            text={props.data.penulis} 
                            weight={"600"}
                            style={{flex : 0.8}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"Penerbit"}
                            weight={"400"} 
                            style={{flex : 0.2}}
                        />
                        <Label
                            size={14}
                            text={ props.data.penerbit} 
                            weight={"600"}
                            style={{flex : 0.8}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"Tahun"}
                            weight={"400"} 
                            style={{flex : 0.2}}
                        />
                        <Label
                            size={14}
                            text={ props.data.tahun_terbit} 
                            weight={"600"}
                            style={{flex : 0.8}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"ISBN"}
                            weight={"400"} 
                            style={{flex : 0.2}}
                        />
                        <Label
                            size={14}
                            text={ (props.data.isbn == "0" ? "-" : props.data.isbn )} 
                            weight={"600"}
                            style={{flex : 0.8}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"EISBN"}
                            weight={"400"} 
                            style={{flex : 0.2}}
                        />
                        <Label
                            size={14}
                            text={ (props.data.eisbn == "0" ? "-" : props.data.eisbn ) } 
                            weight={"600"}
                            style={{flex : 0.8}}
                        />
                    </View>
                    <View style={{ marginLeft : 16, marginRight : 16, marginTop : 16 , height : 0.3, backgroundColor : "#2d3436"}} />
                    <Label
                        size={16}
                        text={"Sitasi"}
                        weight={"600"}
                        textAlign={"left"}
                        style={{
                            marginTop : 16,
                            marginLeft : 16,
                            marginBottom : 8
                        }}
                    />
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"MLA"}
                            weight={"600"} 
                            style={{flex : 0.15}}
                        />
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={14}
                            fontWeight={"400"}
                            component={"span"}
                            style={{ 
                                overflow : "scroll", 
                                flex : 0.85
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: props.data.mla_style}}
                            />
                        </Typography>
                        <PushButton 
                            variant={"text"}
                            text={"Salin"}
                            size={"small"}
                            onClick={()=>{ onCopyClick("MLA")}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"Chicago"}
                            weight={"600"} 
                            style={{flex : 0.15}}
                        />
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={14}
                            fontWeight={"400"}
                            component={"span"}
                            style={{ 
                                overflow : "scroll", 
                                flex : 0.85
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: props.data.chicago_style}}
                            />
                        </Typography>
                        <PushButton 
                            variant={"text"}
                            text={"Salin"}
                            size={"small"}
                            onClick={()=>{ onCopyClick("CHG")}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"Turabian"}
                            weight={"600"} 
                            style={{flex : 0.15}}
                        />
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={14}
                            fontWeight={"400"}
                            component={"span"}
                            style={{ 
                                overflow : "scroll", 
                                flex : 0.85
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: props.data.turabian_style}}
                            />
                        </Typography>
                        <PushButton 
                            variant={"text"}
                            text={"Salin"}
                            size={"small"}
                            onClick={()=>{ onCopyClick("TRB")}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"APA"}
                            weight={"600"} 
                            style={{flex : 0.15}}
                        />
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={14}
                            fontWeight={"400"}
                            component={"span"}
                            style={{ 
                                overflow : "scroll", 
                                flex : 0.85
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: props.data.apa_style}}
                            />
                        </Typography>
                        <PushButton 
                            variant={"text"}
                            text={"Salin"}
                            size={"small"}
                            onClick={()=>{ onCopyClick("APA")}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"Harvard"}
                            weight={"600"} 
                            style={{flex : 0.15}}
                        />
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={14}
                            fontWeight={"400"}
                            component={"span"}
                            style={{ 
                                overflow : "scroll", 
                                flex : 0.85
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: props.data.harvard_style}}
                            />
                        </Typography>
                        <PushButton 
                            variant={"text"}
                            text={"Salin"}
                            size={"small"}
                            onClick={()=>{ onCopyClick("HRV")}}
                        />
                    </View>
                    <View style={{flexDirection : " row", alignItems : "center", marginLeft : 16, marginRight : 16, marginTop : 8 }}>
                        <Label 
                            size={14}
                            text={"IEEE"}
                            weight={"600"} 
                            style={{flex : 0.15}}
                        />
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={14}
                            fontWeight={"400"}
                            component={"span"}
                            style={{ 
                                overflow : "scroll", 
                                flex : 0.85
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: props.data.ieee_style}}
                            />
                        </Typography>
                        <PushButton 
                            variant={"text"}
                            text={"Salin"}
                            size={"small"}
                            onClick={()=>{ onCopyClick("IEE")}}
                        />
                    </View>


                    <PushButton
                        variant={"outlined"}
                        text={"Tutup"} 
                        style={{borderRadius : 8, position : "absolute", bottom : 16, right : 16, width : 90  }}
                        onClick={ props.onClose }
                    />

                </View>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Berhasil disalin!
                    </Alert>
                </Snackbar>
            </Box>
            
        </Modal>
    )
}

//ANCHOR - TRANSACTION DETAIL DIALOG
const TransactionDetailDialog = React.forwardRef((props, innerRef)=>{
    var _is_mounted = false;
    const nf = Intl.NumberFormat(); 
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });
    const [loading, setLoading] = useState({ show : false, message : "Memuat Data"}); 
    const [error, setError] = useState({ show : false, message : ""}); 
    const [data, setData] = useState({}); 
    const [showScrollButton , setShowScrollButton] = useState(false); 
    const viewRef = useRef();
    useImperativeHandle(innerRef, ()=>({
        fetchContent
    }));

    
    const fetchContent = async(id)=>{
        setLoading({ show : true , message : "Memuat data"}); 
        let formData = new FormData();
        formData.append("id_po", id); 
        formData.append("page", 1); 

        const response = await executePost(api.transactionDetail, formData); 
        if(response.code == resCode.ok){
            console.log(JSON.stringify(response));
            setLoading({show : false, message : ""}); 
            setData(response); 

        } else {
            setLoading({ show : false, message : ""}); 
            setError({ show : true, message : response.msg}); 
        }
    }

    const onLayout = (e)=>{
        //console.log("Height: "+e.nativeEvent.layout.height);
        if(isOverflown(e.nativeEvent.target)){
            //setShowScrollButton(true); 
        }
    }

    const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }) => {
        return scrollHeight > clientHeight || scrollWidth > clientWidth;
    }

    const onScrollClick = ()=>{
        
        
    }
    return (
        <ThemeProvider theme={theme}>
            <Modal
                open={ props.open }
            >
                <DialogContent>
                    <View 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: props.width,
                            height : props.height, 
                            backgroundColor: 'white',
                            boxShadow: 24,
                            borderRadius : 15, 
                        }}
                        
                    >

                        <View style={{
                            flexDirection : "row", 
                            alignItems : "center", 
                            margin : 16
                        }}>
                            <Label 
                                size={16}
                                text={"Detail Donasi"}
                                weight={"600"}
                                style={{flex : 1}}
                            />

                            <IconButton
                                onClick={ props.onCloseClick}
                            >
                                <CloseOutlinedIcon 
                                    sx={{width : 24, height : 24}}
                                />
                            </IconButton>
                        </View>
                        <Divider />
                        {
                            loading.show && 
                            <View style={{flex : 1, alignItems : "center", justifyContent : "center"}}>
                                <CircularProgress />
                                <Label 
                                    size={14}
                                    style={{marginTop : 16 }} 
                                    text={ loading.message}
                                />
                            </View>
                        }
                        {
                            error.show &&
                            <View style={{flex : 1, alignItems : "center", justifyContent : "center"}}>
                                <ErrorMark 
                                    width={64}
                                    height={64}
                                />
                                <Label 
                                    size={14}
                                    style={{marginTop : 16}}
                                    text={error.message} 
                                />
                            </View>
                        }
                        {
                            (!loading.show && !error.show && data.hasOwnProperty("jenis") ) && 
                            <View ref={viewRef } style={{flex : 1, margin : 16, overflow : "scroll"}} onLayout={ onLayout }>
                                {
                                    data.jenis == "DONASI_POIN" && 
                                    <>
                                        <View style={{flexDirection : "row", alignItems : "center"}}>
                                            <Label 
                                                size={14}
                                                text={"Sub Total"} 
                                                weight={"400"}
                                                style={{flex : 0.3}}
                                            />
                                            <Label 
                                                size={16}
                                                text={"Rp. " + nf.format(parseInt(data.payment.total_harga))}
                                                weight={"600"}
                                                style={{flex : 0.7}}
                                                textAlign={"end"}
                                            />
                                        </View>

                                        <View style={{flexDirection : "row", alignItems : "center", marginBottom : 16 }}>
                                            <Label 
                                                size={14}
                                                text={"Biaya Transaksi"} 
                                                weight={"400"}
                                                style={{flex : 0.3}}
                                            />
                                            <Label 
                                                size={16}
                                                text={"Rp. " + nf.format(parseInt(data.payment.fee))}
                                                weight={"600"}
                                                textAlign={"end"}
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <Divider />
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16, marginBottom : 16 }}>
                                            <Label 
                                                size={14}
                                                text={"Total"} 
                                                color={"#2980b9"}
                                                weight={"600"}
                                                style={{flex : 0.3}}
                                            />
                                            <Label 
                                                size={16}
                                                text={"Rp. " + nf.format(parseInt(data.payment.grand_total))}
                                                weight={"600"}
                                                textAlign={"end"}
                                                color={"#2980b9"}
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <Divider />
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16, marginBottom : 8 }}>
                                            <Label 
                                                size={14}
                                                text={"Metode Pembayaran"}
                                                style={{flex : 1}}
                                            />
                                            <img
                                                src={ data.payment.logo}
                                                style={{
                                                    width : 64,
                                                    height : 64
                                                }}
                                            />
                                        </View>
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16, marginBottom : 16 }}>
                                            <Label 
                                                size={14}
                                                text={"Status Pembayaran"}
                                                style={{flex : 1}}
                                            />
                                            <View
                                                style={{
                                                    borderRadius : 8,
                                                    paddingLeft : 12, 
                                                    paddingRight : 12, 
                                                    paddingTop : 4, 
                                                    paddingBottom : 4, 
                                                    backgroundColor : (data.payment.status == "2" ? "rgba(46, 204, 113,1.0)" : (data.payment.status == "0" ? "#34495e" : (data.payment.status == "1" ? "rgba(243, 156, 18,1.0)" : "#e74c3c"))),
                                                    alignItems : "center", 
                                                    justifyContent : "center"
                                                }}
                                            >
                                                <Label 
                                                    size={14}
                                                    text={(data.payment.status == "2" ? "Berhasil" : (data.payment.status == "0" ? "Menunggu Pembayaran" : (data.payment.status == "1" ? "Proses Pengecekan" : "Gagal")))}
                                                    weight={"600"}
                                                    textAlign={"end"}
                                                    color={"white"}
                                                />
                                            </View>
                                        </View>
                                    </>
                                }
                                {
                                    data.jenis == "DONASI_BELI" &&
                                    <>
                                        {
                                            data.data.map((i, x)=>(
                                                <View key={"item_idx_"+x} style={{flexDirection : "row", alignItems : "center", marginBottom : (x == data.data.length - 1 ? 16 : 4 )}}>
                                                    <img
                                                        src={i.img_file}
                                                        style={{width : 90, borderRadius : 8}}
                                                    />
                                                    <View style={{flex : 1, marginLeft : 8 , height : "100%"}}>
                                                        <Label 
                                                            size={14}
                                                            weight={"600"}
                                                            text={i.judul}
                                                            style={{
                                                                display : "-webkit-box", 
                                                                WebkitBoxOrient: "vertical", 
                                                                WebkitLineClamp : 1, 
                                                                overflow : "hidden", 
                                                                textOverflow : "ellipsis"
                                                            }}
                                                        />
                                                        <Label 
                                                            size={12}
                                                            weight={"400"}
                                                            text={i.penulis}
                                                            color={"#7f8c8d"}
                                                            style={{
                                                                marginTop : 8,
                                                                display : "-webkit-box", 
                                                                WebkitBoxOrient: "vertical", 
                                                                WebkitLineClamp : 1, 
                                                                overflow : "hidden", 
                                                                textOverflow : "ellipsis"}}
                                                        />
                                                        <Label 
                                                            size={12}
                                                            weight={"400"}
                                                            text={i.penerbit}
                                                            color={"#2980b9"}
                                                            style={{marginTop : 0}}
                                                        />
                                                    </View>
                                                    <Label 
                                                        size={16}
                                                        weight={"400"}
                                                        text={"Rp. " + nf.format(parseInt(i.total_harga))}
                                                    />
                                                </View>
                                            ))
                                        }
                                        <View style={{marginTop : 16 }}/>
                                        <Divider />
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16}}>
                                            <Label 
                                                size={14}
                                                text={"Sub Total"} 
                                                weight={"400"}
                                                style={{flex : 0.3}}
                                            />
                                            <Label 
                                                size={16}
                                                text={"Rp. " + nf.format(parseInt(data.payment.total_harga))}
                                                weight={"600"}
                                                style={{flex : 0.7}}
                                                textAlign={"end"}
                                            />
                                        </View>
                                        <View style={{flexDirection : "row", alignItems : "center", marginBottom : 16 }}>
                                            <Label 
                                                size={14}
                                                text={"Diskon"} 
                                                weight={"400"}
                                                style={{flex : 0.3}}
                                            />
                                            <Label 
                                                size={16}
                                                text={"-Rp. " + nf.format(parseInt(data.payment.diskon_rupiah))}
                                                weight={"600"}
                                                textAlign={"end"}
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <View style={{flexDirection : "row", alignItems : "center", marginBottom : 16 }}>
                                            <Label 
                                                size={14}
                                                text={"Biaya Transaksi"} 
                                                weight={"400"}
                                                style={{flex : 0.3}}
                                            />
                                            <Label 
                                                size={16}
                                                text={"Rp. " + nf.format(parseInt(data.payment.fee))}
                                                weight={"600"}
                                                textAlign={"end"}
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <Divider />
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16, marginBottom : 16 }}>
                                            <Label 
                                                size={14}
                                                text={"Total"} 
                                                color={"#2980b9"}
                                                weight={"600"}
                                                style={{flex : 0.3}}
                                            />
                                            <Label 
                                                size={16}
                                                text={"Rp. " + nf.format(parseInt(data.payment.grand_total))}
                                                weight={"600"}
                                                textAlign={"end"}
                                                color={"#2980b9"}
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <Divider />
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16, marginBottom : 8 }}>
                                            <Label 
                                                size={14}
                                                text={"Metode Pembayaran"}
                                                style={{flex : 1}}
                                            />
                                            <img
                                                src={ data.payment.logo}
                                                style={{
                                                    width : 64,
                                                    height : 64
                                                }}
                                            />
                                        </View>
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16, marginBottom : 16 }}>
                                            <Label 
                                                size={14}
                                                text={"Status Pembayaran"}
                                                style={{flex : 1}}
                                            />
                                            <View
                                                style={{
                                                    borderRadius : 8,
                                                    paddingLeft : 12, 
                                                    paddingRight : 12, 
                                                    paddingTop : 4, 
                                                    paddingBottom : 4, 
                                                    backgroundColor : (data.payment.status == "2" ? "rgba(46, 204, 113,1.0)" : (data.payment.status == "0" ? "#34495e" : (data.payment.status == "1" ? "rgba(243, 156, 18,1.0)" : "#e74c3c"))),
                                                    alignItems : "center", 
                                                    justifyContent : "center"
                                                }}
                                            >
                                                <Label 
                                                    size={14}
                                                    text={(data.payment.status == "2" ? "Berhasil" : (data.payment.status == "0" ? "Menunggu Pembayaran" : (data.payment.status == "1" ? "Proses Pengecekan" : "Gagal")))}
                                                    weight={"600"}
                                                    textAlign={"end"}
                                                    color={"white"}
                                                />
                                            </View>
                                        </View>
                                    </> 
                                }
                                

                                
                            </View>
                        }
                        {
                            showScrollButton && 
                            <IconButton
                                style={{
                                    position : "absolute", 
                                    bottom : 24, 
                                    right : 24,
                                    backgroundColor : "rgba(44, 62, 80,0.3)"
                                }}
                                onClick={ onScrollClick }
                            >
                                <KeyboardArrowDownOutlinedIcon 
                                    sx={{width : 24, height : 24}}
                                />
                            </IconButton>

                        }
                    </View>
                </DialogContent>
            </Modal>
        </ThemeProvider>
    )
})


//ANCHOR - COPY TEXT DIALOG OPT
const CopyTextOptDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    return(
        <ThemeProvider theme={theme}>
            <Modal
                open={ props.open }
            >
                <DialogContent>
                    <View 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: props.width,
                            height : props.height, 
                            backgroundColor: 'white',
                            boxShadow: 24,
                            borderRadius : 15, 
                        }}
                    >
                        <View style={{
                            flexDirection : "row", 
                            alignItems : "center", 
                            margin : 16
                        }}>
                            <Label 
                                size={16}
                                text={"Izin Menyalin"}
                                weight={"600"}
                                style={{flex : 1}}
                            />

                            <IconButton
                                onClick={ props.onCloseClick}
                            >
                                <CloseOutlinedIcon 
                                    sx={{width : 24, height : 24}}
                                />
                            </IconButton>
                        </View>
                        <View style={{ margin : 16, alignItems : "center"}}>
                            <Label  
                                size={14}
                                text={"Izin menyalin buku anda sisa sebanyak"}
                                weight={"400"}
                                style={{marginBottom : 8}}
                            />
                            <View style={{
                                width : 64, 
                                height : 64, 
                                borderRadius : 64, 
                                alignItems : "center", 
                                justifyContent : "center",
                                backgroundColor : clientParams.warna_header
                            }}>
                                <Label 
                                    size={24}
                                    weight={"400"}
                                    text={props.copyRemaining}
                                    color={"white"}
                                />
                            </View>
                        </View>
                        <View style={{position : "absolute", bottom : 16, right : 16, flexDirection : "row-reverse", alignItems: "center"}}>
                            
                            <PushButton
                                variant={"contained"}
                                text={"OK"}
                                onClick={props.onPositiveClick}
                            />
                            <PushButton
                                variant={"outlined"}
                                text={"Batal"}
                                onClick={props.onNegativeClick}
                                style={{marginRight : 12 }}
                            />
                        
                        </View>


                    </View>
                </DialogContent>
            </Modal>

        </ThemeProvider>
    )
}

//ANCHOR - Book Request Dialog 
const BookRequestDialog = (props)=>{
    const clientParams = useSelector(parameters);
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    const [showErrorTitle, setShowErrorTitle] = useState(false); 
    const [showErrorAuthor, setShowErrorAuthor]= useState(false); 
    const [showErrorPublisher, setShowErrorPublisher] = useState(false); 
    const [showErrorIsbn, setShowErrorIsbn] = useState(false); 

    const [bookTitle, setBookTitle] = useState(""); 
    const [bookAuthor, setBookAuthor] = useState(""); 
    const [bookPublisher, setBookPublisher] = useState(""); 
    const [bookISBN, setBookISBN] = useState(""); 
    const [bookYear, setBookYear] = useState(""); 

    const handleSubmitClick = ()=>{
        if(bookTitle == ""){
            setShowErrorTitle(true);
            return; 
        }
        if(bookAuthor == ""){
            setShowErrorAuthor(true);
            return; 
        }
        if(bookPublisher == ""){
            setShowErrorPublisher(true);
            return; 
        }
        if(bookISBN == ""){
            setShowErrorIsbn(true);
            return; 
        }
        let parameters = {
            title : bookTitle, 
            author : bookAuthor, 
            publisher : bookPublisher, 
            isbn : bookISBN,
            year : bookYear
        }

        props.onSubmit(parameters); 
    }
    return(
        <ThemeProvider theme={theme}>
            <Modal
                open={ props.open }
            >
                <DialogContent>
                    <View 
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: props.width,
                            height : props.height, 
                            backgroundColor: 'white',
                            boxShadow: 24,
                            borderRadius : 15, 
                            overflow : "scroll"
                        }}
                    >
                        <View style={{
                            flexDirection : "row", 
                            alignItems : "center", 
                            margin : 16
                        }}>
                            <Label 
                                size={16}
                                text={"Permintaan Buku"}
                                weight={"600"}
                                style={{flex : 1}}
                            />

                            <IconButton
                                onClick={ props.onCloseClick}
                            >
                                <CloseOutlinedIcon 
                                    sx={{width : 24, height : 24}}
                                />
                            </IconButton>
                        </View>
                        <View style={{ margin : 16 }}>

                            <TextField 
                                marginTop={8}
                                marginBottom={12}
                                labelText={"Judul Buku"}
                                placeholder={"Masukkan judul buku"}
                                onChange={(text)=>{ 
                                    if(showErrorTitle){
                                        setShowErrorTitle(false); 
                                    }
                                    setBookTitle(text)
                                }}
                                errorText={"Harap ketik judul buku"}
                                error={showErrorTitle}
                            />
                            <TextField 
                                marginBottom={12}
                                labelText={"Penulis"}
                                placeholder={"Masukkan nama penulis buku"}
                                onChange={(text)=>{ 
                                    if(showErrorAuthor){
                                        setShowErrorAuthor(false)
                                    }
                                    setBookAuthor(text)
                                }}
                                errorText={"Harap ketik nama penulis buku"}
                                error={showErrorAuthor}
                            />
                            <TextField 
                                marginBottom={12}
                                labelText={"Penerbit"}
                                placeholder={"Masukkan nama penerbit buku"}
                                onChange={(text)=>{ 
                                    if(showErrorPublisher){
                                        setShowErrorPublisher(false)
                                    }
                                    setBookPublisher(text)
                                }}
                                errorText={"Harap ketik nama penerbit buku"}
                                error={showErrorPublisher}
                            />
                            <TextField 
                                marginBottom={12}
                                labelText={"Tahun Terbit"}
                                placeholder={"Ketik tahun terbit buku"}
                                onChange={(text)=>{ 
                                    
                                    setBookYear(text)
                                }}
                                errorText={"Harap ketik tahun terbit buku"}
                                error={false}
                            />

                            <TextField 
                                marginBottom={24}
                                labelText={"ISBN"}
                                placeholder={"Masukkan nomor ISBN buku"}
                                onChange={(text)=>{ 
                                    if(showErrorIsbn){
                                        setShowErrorIsbn(false)
                                    }
                                    setBookISBN(text)
                                }}
                                errorText={"Harap ketik nomor ISBN buku"}
                                error={showErrorIsbn}
                            />

                            <PushButton
                                variant={"contained"}
                                text={"Kirimkan Permintaan"}
                                style={{marginBottom : 16, borderRadius : 8 }} 
                                onClick={ handleSubmitClick }
                            />



                        </View>
                    </View>
                </DialogContent>
        
            </Modal>
        </ThemeProvider>
    )
}

const styles = StyleSheet.create({
    container : {
        flex : 1, 
        alignItems : "center", 
        justifyContent : "center"
    }
})




export { Progress, SuccessPage, ErrorPage, 
    ProgressDialog, ErrorDialog, SuccessDialog, BookInfoDialog, CopyTextOptDialog, BookRequestDialog, 
    ForgotPasswordDialog, NewsDetailDialog, ConfirmDialog, TransactionDetailDialog }