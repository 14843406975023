import React, { useState, useEffect  } from 'react'; 
import { View, StyleSheet, Pressable, FlatList, ScrollView } from 'react-native'; 
import { Label } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { useSelector, useDispatch } from 'react-redux'
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog } from '../components/DialogComponents';
import { parameters } from '../reducers/parameterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { Tab , Tabs, CardActionArea } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ContentDetail } from './ContentDetail';


const SectionContent = (props)=>{
    const isMounted = useIsMounted()
    const clientParams = useSelector(parameters); 
    

    const [progressState, setProgressState ] = useState({
        showProgress : false, 
        showError : false, 
        message : ""
    })
    const [firstRun, setFirstRun ] = useState(true); 
    const [bookData, setBookData ] = useState([]); 
    const [renderedData, setRenderedData ] = useState([]); 
    const [pageData, setPageData] = useState({
        id : props.id, 
        page : 1, 
        total : 0, 
        show : 0
    }); 

    useEffect(()=>{

        if(firstRun){
            let progress  = { ...progressState } 
            progress.showProgress = true; 
            progress.message = "Memuat Data"; 
            setProgressState(progress); 
            fetchData();
            setFirstRun(false);
        }

    },[firstRun])

    useEffect(()=>{
        let book_data = [...bookData]; 
        let mod = book_data.length % 7 ; 
        let rendered_data = [...bookData]; 
        if(mod > 0 ) {
            let add_factor = 7  - mod; 
            for(let i = 0; i < add_factor;i++){
                rendered_data.push({ dummy : i}); 
            }
        }
        setRenderedData(rendered_data);

    },[bookData.length])

    const fetchData = async()=>{

        let formData = new FormData(); 
        formData.append("page", pageData.page); 
        formData.append("limit", "100"); 
        formData.append("id_homepage", pageData.id);

        const response = await executePost(api.sectionDetail, formData); 
        let progress = { ...progressState}; 

        if(response.code == resCode.ok){

            progress.showProgress = false; 
            setProgressState(progress); 
            let total = parseInt(response.count);
            let show = parseInt(response.total); 

            if(show > 0 ) {
                let page_data = { ...pageData }
                page_data.total = total; 
                page_data.show += show; 
                let book_data = [...bookData, ...response.data]; 
                setPageData(page_data); 
                setBookData(book_data);
            }

        } else {
            if(response.code != resCode.error){
                progress.showError = true; 
                progress.message = response.msg; 
                setProgressState(progress); 

            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");

            }
        }

    }

    const renderItem = ({item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
            return(
                <Pressable
                    key={"book_"+index}
                    style={{ 
                        borderRadius : 12,
                        borderWidth : 0, 
                        marginRight : (index != renderedData.length - 1 ? 12 : 0 ),
                        marginBottom : 12,
                        cursor : "pointer",
                    }}
                    onPress={()=>{ onContentClick(item)}}
                >
                    <View 
                        style={{
                            alignItems : "center",
                            height : 300
                        }}
                    >
                        <img 
                            src={ item.img_file}
                            style={{
                                marginTop : 8, 
                                width : 120, 
                                borderRadius : 12
                            }}
                        />
                        <Label 
                            size={12}
                            text={item.judul}
                            weight={"600"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                paddingLeft : 8, 
                                paddingRight : 8, 
                                marginTop : 4, 
                                marginBottom : 4, 
                                maxWidth : 120,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label 
                            size={12}
                            text={item.penulis}
                            weight={"600"}
                            color={"#2980b9"}
                            align={"left"}
                            textAlign={"left"}
                            style={{
                                minWidth : 120, 
                                paddingLeft : 8, 
                                paddingRight : 8,  
                                marginTop : 4, 
                                marginBottom : 12, 
                                maxWidth : 120,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis",
                            
                            }}
                        />
                        <View 
                            style={{
                                padding : 4, 
                                borderRadius : 8, 
                                borderWidth : 0.3,
                                borderColor : "#130f40", 
                                alignItems : "center", 
                                justifyContent : "center",
                                position : "absolute", 
                                bottom : 0
                        }}>
                            <Label 
                                size={10}
                                weight={"600"}
                                color={"#130f40"}
                                text={"Stok: "+item.stok + "/" + item.qty}
                                textAlign={"center"}
                            />
                        </View>

                    </View>
                </Pressable>
            )
        } else {
            return(
                <View style={{flex : 1}}>
                </View>
            )
        }
    }

    const fetchMoreData = ()=>{
        if(pageData.show < pageData.total){

            let page_data = { ...pageData }
            page_data.page += 1; 
            setPageData(page_data); 
            setFirstRun(true);

        }
    }

    const [details, setDetails ] = useState({
        show : false,
        id : "", 
        title : ""
    }); 
    

    const onContentClick = (item)=>{
        props.navigation.navigate("DetailPage", item); 
        // let id_konten = item.id_konten; 
        // let judul = item.judul ; 
        // setDetails({
        //     show : true, 
        //     id : id_konten, 
        //     title : judul
        // })
        
    }

    return(
        <View style={{flex : 1 }}>
            <View style={{ flexDirection : "row", alignItems : "center", marginLeft : 16 , marginTop : 16, marginBottom : 16 }}>
                <Pressable
                    onPress={ props.onBackClick }
                >
                    <ArrowBackIcon sx={{ color : clientParams.warna_header }}/> 
                </Pressable>
                <Label 
                    size={14}
                    weight={"600"}
                    style={{marginLeft : 8, cursor : "pointer" }}
                    color={ clientParams.warna_header}
                    text={ props.title }
                />
            </View>

            <FlatList 
                keyExtractor={(item, index)=>"book_index_"+index} 
                data={ renderedData }
                renderItem={ renderItem } 
                numColumns={7}
                showsVerticalScrollIndicator={false}
                onEndReachedThreshold={ 0.5 }
                onEndReached={ fetchMoreData }
            />

            <ErrorDialog 
                open={ progressState.showError}
                message={ progressState.message }
                label={"OK"}
                onClick={()=>{
                    let params = { ...progressState};
                    params.showError = false;
                    setProgressState(params);
                }}
            />
            <ProgressDialog 
                open={ progressState.showProgress }
                message={ progressState.message }
            />
            {/* <ContentDetail 
                open={ details.show }
                start={ details.show }
                id={ details.id }
                title={ details.title }
                onClose={()=>{
                    let _details = {...details}
                    _details.show = false; 
                    setDetails(_details);
                }}
                navigation={props.navigation}
            /> */}
        </View>
    )


}


export { SectionContent }