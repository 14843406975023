import * as React from "react"
import Svg, { G, Rect, Path, Circle, Polyline, Line } from "react-native-svg"

const IconHome = (props)=>{
    return(
        <Svg
            width={ props.width }
            height={ props.height }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M3 10.8074C3 9.9094 3.40231 9.0586 4.09639 8.48876L9.46186 4.08378C10.9372 2.87254 13.0628 2.87255 14.5381 4.08378L19.9036 8.48876C20.5977 9.0586 21 9.9094 21 10.8074V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V10.8074Z"
                stroke={props.color}
                strokeWidth={2}
            />
            <Path
                d="M15 21V16C15 14.8954 14.1046 14 13 14H11C9.89543 14 9 14.8954 9 16V21"
                stroke={ props.color }
                strokeWidth={2}
            />
        </Svg>
    )
}

const IconNews = (props)=>{
    return(
        <Svg
      
            width={props.width}
            height={props.height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
            d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke={ props.color }
            strokeWidth={2}
            />
            <Path
            d="M16 12C16 16.9706 14.2091 21 12 21C9.79086 21 8 16.9706 8 12C8 7.02944 9.79086 3 12 3C14.2091 3 16 7.02944 16 12Z"
            stroke={ props.color }
            strokeWidth={2}
            />
            <Path
            d="M20 16.795C18.2145 15.7053 15.3666 15 12.1583 15C8.75309 15 5.75391 15.7945 4 17"
            stroke={ props.color }
            strokeWidth={2}
            />
            <Path
            d="M20 7.08688C18.2506 8.24353 15.3484 9 12.0641 9C8.69818 9 5.73365 8.2055 4 7"
            stroke={ props.color }
            strokeWidth={2}
            />
        </Svg>
    )
}


const IconFolder = (props)=>{
    return(
        <Svg
            width={props.width}
            height={props.height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
            d="M3 6C3 4.34315 4.34315 3 6 3H8.67963C9.34834 3 9.9728 3.3342 10.3437 3.8906L11.4063 5.4844C11.7772 6.0408 12.4017 6.375 13.0704 6.375H18C19.6569 6.375 21 7.71815 21 9.375V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6Z"
            stroke={ props.color }
            strokeWidth={2}
            />
        </Svg>
    )
}

const IconBag = (props)=>{
    return(
        <Svg
            width={ props.width }
            height={ props.height }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
            d="M3.48921 10.4243C3.23103 8.61696 4.63341 7 6.45906 7H17.541C19.3667 7 20.769 8.61696 20.5109 10.4243L19.4906 17.5657C19.2091 19.5363 17.5215 21 15.5308 21H8.46921C6.47861 21 4.79093 19.5363 4.50941 17.5657L3.48921 10.4243Z"
            stroke={ props.color }
            strokeWidth={2}
            />
            <Path
            d="M17 7L16.1056 5.21117C15.428 3.85602 14.043 3 12.5279 3H11.4721C9.95705 3 8.57215 3.8557 7.89458 5.21084L7 7"
            stroke={ props.color }
            strokeWidth={2}
            strokeLinecap="round"
            />
        </Svg>
    )
}

const IconCart = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1 1C0.447715 1 0 1.44772 0 2C0 2.55228 0.447715 3 1 3H3.20647L5.98522 14.9089C6.39883 16.6816 7.95486 17.9464 9.76471 17.9983V18H17.5874C19.5362 18 21.2014 16.5956 21.5301 14.6747L22.7857 7.33734C22.9947 6.11571 22.0537 5 20.8143 5H5.72686L4.97384 1.77277C4.86824 1.32018 4.46475 1 4 1H1ZM6.19353 7L7.9329 14.4545C8.14411 15.3596 8.95109 16 9.88058 16H17.5874C18.5618 16 19.3944 15.2978 19.5588 14.3373L20.8143 7H6.19353Z"
                fill={props.color}
            />
            <Path
                d="M8 23C9.10457 23 10 22.1046 10 21C10 19.8954 9.10457 19 8 19C6.89543 19 6 19.8954 6 21C6 22.1046 6.89543 23 8 23Z"
                fill={ props.color }
            />
            <Path
                d="M19 23C20.1046 23 21 22.1046 21 21C21 19.8954 20.1046 19 19 19C17.8954 19 17 19.8954 17 21C17 22.1046 17.8954 23 19 23Z"
                fill={ props.color }
            />
            </G>
        </Svg>
    )
    
}

const IconUser = (props)=>{
    return(
        <Svg
            width={ props.width }
            height={ props.height }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <Path
            d="M5 19C5 16.7909 6.79086 15 9 15H15C17.2091 15 19 16.7909 19 19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19Z"
            stroke={ props.color }
            strokeWidth={2}
            />
            <Circle cx={12} cy={7} r={4} stroke={ props.color } strokeWidth={2} />
        </Svg>
    )
}

const IconAppIntegrate = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            fill="none"
            width={ props.width }
            height={ props.height }
            xmlns="http://www.w3.org/2000/svg"
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path
                d="M6 13C6.55 13 7 12.55 7 12C7 11.45 6.55 11 6 11C5.45 11 5 11.45 5 12C5 12.55 5.45 13 6 13Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M6 7C6.55 7 7 6.55 7 6C7 5.45 6.55 5 6 5C5.45 5 5 5.45 5 6C5 6.55 5.45 7 6 7Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M6 19C6.55 19 7 18.55 7 18C7 17.45 6.55 17 6 17C5.45 17 5 17.45 5 18C5 18.55 5.45 19 6 19Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M12 13C12.55 13 13 12.55 13 12C13 11.45 12.55 11 12 11C11.45 11 11 11.45 11 12C11 12.55 11.45 13 12 13Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M12 7C12.55 7 13 6.55 13 6C13 5.45 12.55 5 12 5C11.45 5 11 5.45 11 6C11 6.55 11.45 7 12 7Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M12 19C12.55 19 13 18.55 13 18C13 17.45 12.55 17 12 17C11.45 17 11 17.45 11 18C11 18.55 11.45 19 12 19Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M18 13C18.55 13 19 12.55 19 12C19 11.45 18.55 11 18 11C17.45 11 17 11.45 17 12C17 12.55 17.45 13 18 13Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M18 7C18.55 7 19 6.55 19 6C19 5.45 18.55 5 18 5C17.45 5 17 5.45 17 6C17 6.55 17.45 7 18 7Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            <Path
                d="M18 19C18.55 19 19 18.55 19 18C19 17.45 18.55 17 18 17C17.45 17 17 17.45 17 18C17 18.55 17.45 19 18 19Z"
                stroke={ props.color }
                strokeWidth={2}
            />
            </G>
        </Svg>
    )
}

const IconHand = (props)=>{
    return(
        <Svg
            width={props.width}
            height={props.height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke={ props.color }
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path d="M18 11V6a2 2 0 00-2-2v0a2 2 0 00-2 2v0" />
            <Path d="M14 10V4a2 2 0 00-2-2v0a2 2 0 00-2 2v2" />
            <Path d="M10 10.5V6a2 2 0 00-2-2v0a2 2 0 00-2 2v8" />
            <Path d="M18 8a2 2 0 114 0v6a8 8 0 01-8 8h-2c-2.8 0-4.5-.86-5.99-2.34l-3.6-3.6a2 2 0 012.83-2.82L7 15" />
            </G>
        </Svg>
    )
}

const IconText = (props)=>{
    return(
        <Svg
            fill={ props.color }
            viewBox="0 0 14 14"
            role="img"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width }
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path d="m 7.430568,2.63427 0,5.65744 1.363008,0 0,0.86113 -1.363008,0 0,2.21289 c 0,0.75143 1.209266,0.77313 1.34722,0.77313 l 0,0.86114 C 8.54907,13 7.961488,12.9724 7.448021,12.71726 7.274071,12.63076 7.124348,12.52551 7,12.40421 6.875709,12.52551 6.725986,12.63086 6.552037,12.71726 6.038512,12.97244 5.450987,13 5.222212,13 l 0,-0.86114 c 0.137896,0 1.347219,-0.0217 1.347219,-0.77313 l 0,-2.21289 -1.363007,0 0,-0.86113 1.363007,0 0,-5.65744 c 0,-0.75143 -1.209323,-0.77313 -1.347219,-0.77313 L 5.222212,1 C 5.450987,1 6.038512,1.02756 6.551979,1.282683 6.725986,1.369143 6.875709,1.474429 7,1.59579 7.124291,1.474486 7.274014,1.369141 7.448021,1.282683 7.961488,1.027556 8.549013,1 8.777731,1 l 0,0.86114 c -0.137897,0 -1.347163,0.0217 -1.347163,0.77313 z" />
            </G>
        </Svg>
    )
}

const IconMessage = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="#000000"
            width={ props.width }
            heigght={ props.height }
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <G
                id="Page-1"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <G id="Message">
                <Rect
                    id="Rectangle"
                    fillRule="nonzero"
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                />
                <Path
                    d="M4,6 C4,4.89543 4.89543,4 6,4 L18,4 C19.1046,4 20,4.89543 20,6 L20,14 C20,15.1046 19.1046,16 18,16 L12.2071,16 C12.0745,16 11.9473,16.0527 11.8536,16.1464 L8.85355,19.1464 C8.53857,19.4614 8,19.2383 8,18.7929 L8,16.5 C8,16.2239 7.77614,16 7.5,16 L6,16 C4.89543,16 4,15.1046 4,14 L4,6 Z"
                    id="Path"
                    stroke="#0C0310"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <Line
                    x1={8}
                    y1={8}
                    x2={16}
                    y2={8}
                    id="Path"
                    stroke="#0C0310"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <Line
                    x1={8}
                    y1={12}
                    x2={11}
                    y2={12}
                    id="Path"
                    stroke="#0C0310"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                </G>
            </G>
            </G>
        </Svg>
    )
}

const IconMenu = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="#000000"
            width={props.width}
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <G
                id="Page-1"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <G id="Bullet-List">
                <Rect
                    id="Rectangle"
                    fillRule="nonzero"
                    x={0}
                    y={0}
                    width={24}
                    height={24}
                />
                <Line
                    x1={10}
                    y1={7}
                    x2={19}
                    y2={7}
                    id="Path"
                    stroke={ props.color }
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <Line
                    x1={5}
                    y1={7}
                    x2={5.1}
                    y2={7}
                    id="Path"
                    stroke={ props.color }
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <Line
                    x1={10}
                    y1={17}
                    x2={19}
                    y2={17}
                    id="Path"
                    stroke={ props.color }
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <Line
                    x1={5}
                    y1={17}
                    x2={5.1}
                    y2={17}
                    id="Path"
                    stroke={props.color}
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <Line
                    x1={10}
                    y1={12}
                    x2={19}
                    y2={12}
                    id="Path"
                    stroke={ props.color }
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                <Line
                    x1={5}
                    y1={12}
                    x2={5.1}
                    y2={12}
                    id="Path"
                    stroke={ props.color }
                    strokeWidth={2}
                    strokeLinecap="round"
                />
                </G>
            </G>
            </G>
        </Svg>
    )
}

const IconDelete = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path
                d="M6 7V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V7M6 7H5M6 7H8M18 7H19M18 7H16M10 11V16M14 11V16M8 7V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V7M8 7H16"
                stroke="#34495e"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            </G>
        </Svg>
    )
}

const IconArea = (props)=>{
    return(
        <Svg
            fill={props.color}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path d="M1,7V3A1,1,0,0,1,2,2H6A1,1,0,0,1,6,4H3V7A1,1,0,0,1,1,7ZM2,22H6a1,1,0,0,0,0-2H3V17a1,1,0,0,0-2,0v4A1,1,0,0,0,2,22Zm21-5a1,1,0,0,0-2,0v3H18a1,1,0,0,0,0,2h4a1,1,0,0,0,1-1ZM17,3a1,1,0,0,0,1,1h3V7a1,1,0,0,0,2,0V3a1,1,0,0,0-1-1H18A1,1,0,0,0,17,3ZM10,22h4a1,1,0,0,0,0-2H10a1,1,0,0,0,0,2ZM23,10.5a1,1,0,0,0-2,0v3a1,1,0,0,0,2,0Zm-21,4a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,2,14.5ZM10,4h4a1,1,0,0,0,0-2H10a1,1,0,0,0,0,2Z" />
            </G>
        </Svg>
    )
}

const IconTextToSpeech = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            fill="#000000"
            width={props.width}
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <G
                id="\uD83D\uDD0D-Product-Icons"
                stroke="none"
                strokeWidth={1}
                fill="none"
                fillRule="evenodd"
            >
                <G
                id="ic_fluent_read_aloud_24_filled"
                fill={"#0C0310"}
                fillRule="nonzero"
                >
                <Path
                    d="M9.9000522,4.5617157 L9.94234572,4.66365002 L14.9423457,18.6630127 C15.1281067,19.1831197 14.8570657,19.7553388 14.3369586,19.9410998 C13.8540021,20.1135921 13.3261123,19.8922092 13.1044329,19.4432762 L13.0588716,19.3357127 L11.5092217,14.99994 L6.48960924,14.9997163 L4.94161598,19.3357553 C4.75583148,19.8558539 4.18360015,20.126869 3.66350154,19.9410845 C3.18341052,19.7695911 2.91555631,19.2688176 3.02331328,18.7837186 L3.05817231,18.6629701 L8.05888683,4.6636074 C8.34967184,3.84956276 9.43208373,3.78446316 9.84949785,4.46827563 L9.9000522,4.5617157 Z M15.8612753,2.09351958 C17.9470123,3.23306377 19.5127784,4.69618867 20.5430129,6.48060721 C21.5732475,8.26502575 22.0574677,10.3525815 22.0014735,12.7286547 C21.9917149,13.1427533 21.6481107,13.4705357 21.2340121,13.4607771 C20.8199136,13.4510185 20.4921312,13.1074143 20.5018898,12.6933157 C20.5516452,10.5819824 20.130407,8.76595272 19.2439748,7.23060721 C18.3575427,5.69526171 16.995434,4.42244386 15.142088,3.40986662 C14.7785888,3.21126852 14.64491,2.75559862 14.8435081,2.39209942 C15.0421062,2.02860022 15.4977761,1.89492147 15.8612753,2.09351958 Z M15.6237146,5.6941638 C16.5907076,6.2214405 17.3656967,6.9887979 17.9399727,7.98347319 C18.5142488,8.97814848 18.7913052,10.0329874 18.7644437,11.1340663 C18.7543418,11.5481566 18.4104659,11.8756539 17.9963756,11.8655519 C17.5822852,11.85545 17.2547879,11.5115741 17.2648899,11.0974838 C17.2849329,10.2758998 17.0791806,9.49253752 16.6409346,8.73347319 C16.2026886,7.97440887 15.6271532,7.40454099 14.905619,7.01110678 C14.5419553,6.81281017 14.4078987,6.35725128 14.6061953,5.99358752 C14.8044919,5.62992376 15.2600508,5.4958672 15.6237146,5.6941638 Z M9.00054138,7.97290533 L7.20470437,13.0002974 L10.7955434,12.999813 L9.00054138,7.97290533 Z"
                    id="\uD83C\uDFA8-Color"
                />
                </G>
            </G>
            </G>
        </Svg>
    )
}

const IconCopy = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path
                d="M7.5 3H14.6C16.8402 3 17.9603 3 18.816 3.43597C19.5686 3.81947 20.1805 4.43139 20.564 5.18404C21 6.03969 21 7.15979 21 9.4V16.5M6.2 21H14.3C15.4201 21 15.9802 21 16.408 20.782C16.7843 20.5903 17.0903 20.2843 17.282 19.908C17.5 19.4802 17.5 18.9201 17.5 17.8V9.7C17.5 8.57989 17.5 8.01984 17.282 7.59202C17.0903 7.21569 16.7843 6.90973 16.408 6.71799C15.9802 6.5 15.4201 6.5 14.3 6.5H6.2C5.0799 6.5 4.51984 6.5 4.09202 6.71799C3.71569 6.90973 3.40973 7.21569 3.21799 7.59202C3 8.01984 3 8.57989 3 9.7V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21Z"
                stroke="#0C0310"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            </G>
        </Svg>
    )
}

const IconTransaction = (props)=>{
    return(
        <Svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width={props.width}
            height={props.height}
        >
            <G id="SVGRepo_bgCarrier" strokeWidth={0} />
            <G
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
            <G id="SVGRepo_iconCarrier">
            <Path
                d="M13.25 8.5C12.8358 8.5 12.5 8.83579 12.5 9.25C12.5 9.66421 12.8358 10 13.25 10H16.75C17.1642 10 17.5 9.66421 17.5 9.25C17.5 8.83579 17.1642 8.5 16.75 8.5H13.25Z"
                fill={props.color}
            />
            <Path
                d="M12.5001 14.75C12.5001 14.3358 12.8358 14 13.2501 14H16.7499C17.1642 14 17.4999 14.3358 17.4999 14.75C17.4999 15.1642 17.1642 15.5 16.7499 15.5H13.2501C12.8358 15.5 12.5001 15.1642 12.5001 14.75Z"
                fill={props.color}
            />
            <Path
                d="M10.7803 7.71967C11.0732 8.01256 11.0732 8.48744 10.7803 8.78033L8.78033 10.7803C8.48744 11.0732 8.01256 11.0732 7.71967 10.7803L6.71967 9.78033C6.42678 9.48744 6.42678 9.01256 6.71967 8.71967C7.01256 8.42678 7.48744 8.42678 7.78033 8.71967L8.25 9.18934L9.71967 7.71967C10.0126 7.42678 10.4874 7.42678 10.7803 7.71967Z"
                fill={props.color}
            />
            <Path
                d="M10.7803 14.2803C11.0732 13.9874 11.0732 13.5126 10.7803 13.2197C10.4874 12.9268 10.0126 12.9268 9.71967 13.2197L8.25 14.6893L7.78033 14.2197C7.48744 13.9268 7.01256 13.9268 6.71967 14.2197C6.42678 14.5126 6.42678 14.9874 6.71967 15.2803L7.71967 16.2803C8.01256 16.5732 8.48744 16.5732 8.78033 16.2803L10.7803 14.2803Z"
                fill={props.color}
            />
            <Path
                d="M5.25 3C4.00736 3 3 4.00736 3 5.25V18.75C3 19.9926 4.00736 21 5.25 21H18.75C19.9926 21 21 19.9926 21 18.75V5.25C21 4.00736 19.9926 3 18.75 3H5.25ZM4.5 5.25C4.5 4.83579 4.83579 4.5 5.25 4.5H18.75C19.1642 4.5 19.5 4.83579 19.5 5.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H5.25C4.83579 19.5 4.5 19.1642 4.5 18.75V5.25Z"
                fill={props.color}
            />
            </G>
        </Svg>
    )
}

export { IconHome, IconNews, IconFolder, IconBag, IconCart, IconUser,
    IconAppIntegrate, IconHand, IconText, IconMessage, IconMenu, IconDelete, IconArea, 
    IconTextToSpeech, IconCopy, IconTransaction }