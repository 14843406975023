import React, { useState, useEffect, useRef } from 'react'; 
import { View, StyleSheet, FlatList,Pressable, Dimensions , TextInput} from 'react-native'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog,TransactionDetailDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams, clientAdditionalParams } from '../reducers/parameterSlice';
import { Label, PushButton, PushChip } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { Paper, DialogContent, CircularProgress, Modal, ButtonBase, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//status belum proses, 0=belum proses, 1=konfirmasi, 2=sukses, 3=return buku, 9 = hapus 
const TransactionList = (props)=>{
    const isMounted = useIsMounted(); 
    var _is_mounted = false;
    const nf = Intl.NumberFormat();
    const clientParams = useSelector(parameters); 
    const [progressState, setProgressState ] = useState({
        showProgressDialog : true, 
        showProgress : false, 
        showError : false, 
        showSuccess : false, 
        message : "Memuat Data"
    })
    
    const [status, setStatus ] = useState("-1"); 
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });
    const [transactionList, setTransactions] = useState([]); 
    const [renderedData, setRenderedData] = useState([]); 
    const [pageState, setPageState] = useState({ page : 1, show : 0, total : 0}); 
    const [showDetail, setShowDetail] = useState(false); 
    const [details, setDetails] = useState({}); 
    const [selectedId, setSelectedId] = useState(); 
    const [detailType, setDetailType] = useState("DONASI_BELI"); 
    
    const IDPoRef = useRef(null);   
    const detailRef = useRef(); 

    useEffect(()=>{
        _is_mounted = true; 
        //console.log("Status: " + status);
        fetchTransaction(); 
        return ()=>{
            _is_mounted = false;
        }
    },[pageState.page, status])

    useEffect(()=>{
        if(showDetail){
           detailRef.current.fetchContent(selectedId); 
        }
    },[showDetail])

    //ANCHOR - FETCH TRANSACTION    
    const fetchTransaction = async()=>{
        let formData = new FormData(); 
        formData.append("status", status)
        formData.append("page", pageState.page);
        formData.append("limit", 30); 
        let _progressState = {...progressState} 
        const response = await executePost(api.transactionList, formData); 
        _progressState.showProgressDialog = false;
        if(_is_mounted){
            if(response.code == resCode.ok){
                //console.log(JSON.stringify(response));
                setProgressState(_progressState);
                let _pageState = {...pageState}
                _pageState.total = response.total;
                if(response.show > 0 ){
                    let show = _pageState.show += response.show; 
                    let data = [...transactionList, ...response.data]; 
                    setTransactions(data); 
                    let mod = data.length % 2; 
                    if(mod > 0 ) {
                        let add_factor = 2 - mod; 
                        for(let i=0;i < add_factor;i++){
                            data.push({dummy : i}); 
                        }
                    }
                    setRenderedData(data); 
                    setPageState(_pageState); 

                }
                props.onUpdateTrx(response.pending); 
            } else {
                if(response.code != resCode.error){
                    _progressState.showError = true; 
                    _progressState.message = response.msg; 
                    setProgressState(_progressState);
                } else {
                    //ANCHOR - Force Logout
                    window.postMessage({command : "FORCE_LOGOUT"}, "*");
                }
            }
        }
    }

    //ANCHOR - HANDLE CHANGE STATUS
    const handleChangeStatus = (which)=>{
        let _pageState = { page : 1, show : 0 , total : 0}
        setPageState(_pageState);
        let _progressState = {...progressState}
        _progressState.showProgressDialog = true; 
        _progressState.message = "Memuat Data";
        setProgressState(_progressState); 
        setRenderedData([]);
        setTransactions([]);
        setStatus(which); 
    }

    //ANCHOR - RENDER ITEM
    const renderItem = ({item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
            return(
                <Paper style={{flex : 1, margin : 8, borderRadius : 8 }} 
                    elevation={3}
                >
                    <View 
                        style={{
                            flexDirection : "row", 
                            alignItems : "center", 
                            paddingTop : 12, 
                            paddingBottom : 12, 
                            paddingLeft : 12 , 
                            paddingRight : 12
                        }}
                    >
                        <View style={{flex : 1}}>
                            <Label
                                size={14}
                                weight={"600"}
                                text={ item.jenis == "DONASI_POIN" ? "Donasi Poin" : "Donasi Buku"}
                            />
                            <Label
                                size={14}
                                color={"#7f8c8d"}
                                weight={"600"}
                                text={item.tgl_input}
                            />
                        </View>
                        {
                            item.sts == "0" && 
                            <View style={{ backgroundColor : "#34495e", borderRadius : 8 , paddingLeft : 12, paddingRight : 12, paddingTop : 4, paddingBottom : 4, alignItems : "center", justifyContent : "center"}}>
                                <Label 
                                    size={10}
                                    color={"white"}
                                    weight={"600"}
                                    text={"Menunggu Pembayaran"}
                                />
                            </View>
                        }
                        {
                            item.sts == "1" && 
                            <View style={{ backgroundColor : "rgba(243, 156, 18,1.0)", borderRadius : 8 , paddingLeft : 12, paddingRight : 12, paddingTop : 4, paddingBottom : 4, alignItems : "center", justifyContent : "center"}}>
                                <Label 
                                    size={10}
                                    color={"white"}
                                    weight={"600"}
                                    text={"Menunggu Konfirmasi"}
                                />
                            </View>
                        }
                        {
                            item.sts == "2" && 
                            <View style={{ backgroundColor : "rgba(46, 204, 113,1.0)", borderRadius : 8 , paddingLeft : 12, paddingRight : 12, paddingTop : 4, paddingBottom : 4, alignItems : "center", justifyContent : "center"}}>
                                <Label 
                                    size={10}
                                    color={"white"}
                                    weight={"600"}
                                    text={"Selesai"}
                                />
                            </View>
                        }
                        {
                            item.sts == "9" && 
                            <View style={{ backgroundColor : "rgba(231, 76, 60,1.0)", borderRadius : 8 , paddingLeft : 12, paddingRight : 12, paddingTop : 4, paddingBottom : 4, alignItems : "center", justifyContent : "center"}}>
                                <Label 
                                    size={10}
                                    color={"white"}
                                    weight={"600"}
                                    text={"Gagal"}
                                />
                            </View>
                        }
                    </View>
                    <View style={{backgroundColor : "#ecf0f1", height : 0.5, width : "100%", marginBottom : 8 }} />
                    <View 
                        style={{
                            flexDirection : "row", 
                            alignItems : "center", 
                            paddingLeft : 12 , 
                            paddingRight : 12
                        }}
                    >
                        <View style={{flex : 1}}>
                            <Label
                                size={12}
                                weight={"400"}
                                text={"Total"}
                            />
                            <Label 
                                size={14}
                                weight={"600"}
                                color={"#2980b9"}
                                text={"Rp. " + nf.format(parseInt(item.grand_total))}
                            />
                        </View>
                        <View>
                            <Label 
                                size={12}
                                text={"Metode Pembayaran"}
                            />
                            <Label 
                                size={14}
                                weight={"600"}
                                text={item.metode_pembayaran}
                            />
                        </View>
                    </View>
                    <View 
                        style={{
                            flexDirection : "row", 
                            alignItems : "center", 
                            marginTop : 8 , 
                            paddingLeft : 12 , 
                            paddingRight : 12,
                            marginBottom : 16
                        }}
                    >
                        <View>
                            <Label
                                size={12}
                                weight={"400"}
                                text={"Cara Bayar"}
                            />
                            <Label 
                                size={14}
                                weight={"600"}
                                text={item.deskripsi}
                            />
                            {
                                item.sts == "0" && 
                                <View style={{flexDirection : "row-reverse"}}>
                                    <Pressable
                                        onPress={()=>{
                                            window.electron.sendMessage("OPEN_URL", item.payment_instructions)
                                        }}
                                    >
                                        <Label 
                                            size={10}
                                            text={"Klik disini untuk cara membayar"} 
                                            color={"#3742fa"}
                                        />
                                    </Pressable>
                                </View>
                            }
                            
                        </View>
                        
                    </View>
                    <View 
                        style={{
                            flexDirection : "row-reverse", 
                            alignItems : "center", 
                            marginTop : 8 , 
                            paddingLeft : 12 , 
                            paddingRight : 12,
                            marginBottom : 16
                        }}
                    >
                        <PushButton 
                            text={"Detail"}
                            variant={"contained"}
                            style={{borderRadius : 8 }}
                            onClick={()=>{
                                onDetailClick(item)
                            }}
                        />
                    </View>






                </Paper>
            )
        } else {
            return(
                <View style={{flex : 1}}>
                </View>
            )
        }
    }

    //ANCHOR - SHOW DETAILS
    const onDetailClick = (item)=>{
        let id = item.id; 
        let type = item.payment_type; 
        IDPoRef.current = id; 
        setDetailType(type); 
        setSelectedId(id); 
        setShowDetail(true); 
        
    }
    //ANCHOR - FECTH DETAILS
    const fetchDetails = async(id)=>{
        let formData = new FormData(); 
        let _progressState = { ...progressState }
        formData.append("id_po", id);
        formData.append("page", 1); 
        const response = await executePost(api.transactionDetail, formData); 
        _progressState.showProgressDialog = false; 
        if(response.code == resCode.ok){
            setDetails(response); 
        } else {
            if(response.code != resCode.error){
                _progressState.showError = true; 
                _progressState.message = response.msg; 
                setProgressState(_progressState);
            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }

    }
    return(
        <ThemeProvider theme={ theme }>
            <View style={{flex : 1}}>
                <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginTop : 16 }}>
                    
                    <Label 
                        size={16}
                        weight={"600"}
                        text={"Pilih Transaksi"}
                        color={ clientParams.warna_header }
                    />
                    <PushChip
                        variant={(status == "-1" ? "contained" : "outlined")}
                        color={"primary"}
                        label={"Semua Transaksi"}
                        style={{marginLeft : 12}}
                        onClick={()=>{
                            handleChangeStatus("-1");
                        }}
                    />
                    <PushChip
                        variant={(status == "0" ? "contained" : "outlined")}
                        color={"primary"}
                        label={"Menunggu Pembayaran"}
                        style={{marginLeft : 12}}
                        onClick={()=>{
                            handleChangeStatus("0");
                        }}
                    />
                    <PushChip
                        variant={(status == "1" ? "contained" : "outlined")}
                        color={"primary"}
                        label={"Menunggu Konfirmasi"}
                        style={{marginLeft : 12}}
                        onClick={()=>{
                            handleChangeStatus("1");
                        }}
                    />
                    <PushChip
                        variant={(status == "2" ? "contained" : "outlined")}
                        color={"primary"}
                        label={"Sukses"}
                        style={{marginLeft : 12}}
                        onClick={()=>{
                            handleChangeStatus("2");
                        }}
                    />
                    <PushChip
                        variant={(status == "9" ? "contained" : "outlined")}
                        color={"primary"}
                        label={"Gagal"}
                        style={{marginLeft : 12}}
                        onClick={()=>{
                            handleChangeStatus("9");
                        }}
                    />
                </View>
                {
                    !progressState.showProgressDialog && transactionList.length > 0 && 
                    <FlatList 
                        contentContainerStyle={{ margin : 16 }}
                        keyExtractor={(i,x)=>"trx_idx_"+x}
                        data={ transactionList } 
                        renderItem={renderItem}
                        numColumns={2}
                        showsVerticalScrollIndicator={false} 
                    />
                }
            </View>
            <ProgressDialog 
                open={progressState.showProgressDialog}
                message={progressState.message }
            />
            <ErrorDialog 
                open={ progressState.showError }
                message={ progressState.message } 
                label={"OK"}
                onClick={()=>{
                    let _progressState = { ...progressState } 
                    _progressState.showError = false; 
                    setProgressState(_progressState); 
                    
                }}
            />
            <TransactionDetailDialog 
                ref={ detailRef }
                open={ showDetail }
                width={550}
                height={(detailType == "DONASI_BELI" ? 750 : 550)}
                id={ selectedId}
                onCloseClick={()=>{
                    setShowDetail(false);
                }}
            />
        </ThemeProvider>
    )
}

export { TransactionList }