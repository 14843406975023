import React, { Component } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
//REDUX 
import store from './reducers/store'; 
import { Provider } from 'react-redux';

import { NavigationContainer, getFocusedRouteNameFromRoute, useNavigationContainerRef, StackActions } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

const Stack = createNativeStackNavigator();

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
const platform = params.platform; 

import { Splash as SplashDesktop } from './desktop/Splash';
import { Splash as SplashMobile } from './mobile/Splash';

import { Login as LoginDesktop } from './desktop/Login';
import { SignUp as SignUpDesktop } from './desktop/SignUp';
import { Main as MainDesktop } from './desktop/Main';
import { ReaderX as ReaderDesktop } from './desktop/ReaderX';
import { DetailPage as DetailPageDesktop } from './desktop/DetailPage';
import { LoginUnibraw } from './desktop/sso/Unibraw';

export default function App() {
  const navigationRef = useNavigationContainerRef();
  React.useEffect(()=>{
      
  })

  if(platform == "mobile") {
    return(
      <Provider store={ store}>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen
            name={"Splash"}
            component={SplashMobile}
            options={{
              title : "Welcome", 
              headerShown : false
            }}
          />
        
        </Stack.Navigator>
      </NavigationContainer>
      </Provider> 
    )
    
  } else {
    return (
      <Provider store={ store}>
        <NavigationContainer>
          <Stack.Navigator>
            <Stack.Screen
              name={"Splash"}
              component={SplashDesktop}
              options={{
                title : "Welcome", 
                headerShown : false
              }}
            />
            <Stack.Screen 
              name={"LoginUnibraw"}
              component={LoginUnibraw}
              options={{
                title : "Welcome", 
                headerShown : false
              }}
            />
            <Stack.Screen
              name={"Login"}
              component={LoginDesktop}
              options={{
                title : "Welcome", 
                headerShown : false
              }}
            />
            <Stack.Screen
              name={"SignUp"}
              component={SignUpDesktop}
              options={{
                title : "Welcome", 
                headerShown : false
              }}
            />
            <Stack.Screen 
              name={"Main"}
              component={MainDesktop}
              options={{
                title : "Welcome",
                headerShown : false
              }}
            />
            <Stack.Screen 
              name={"Reader"}
              component={ReaderDesktop}
              options={{
                title : "Reading",
                headerShown : false
              }}
            />
            <Stack.Screen 
              name={"DetailPage"}
              component={DetailPageDesktop}
              options={{
                title : "Detail Content",
                headerShown : false
              }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </Provider>
    );
  }

  
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
