import React, { useEffect, useState, useRef } from 'react'; 
import { View, StyleSheet, ScrollView, Pressable, Image } from 'react-native'; 
import { Label, TextField, PushButton, Options, Checklist, ComboBox } from '../components/FormComponents'; 
import { useSelector } from 'react-redux'
import { parameters } from '../reducers/parameterSlice';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { CardPlaceholder } from '../assets/Placeholders';
import { Card } from '@mui/material';
import * as DocumentPicker from 'expo-document-picker'; 
import { dataURLToFile } from '../utilities/System';
import { executePost, api, resCode } from '../utilities/Network';
import { ProgressDialog, ErrorDialog, SuccessDialog } from '../components/DialogComponents';

//== SignUp Desktop ==// 

const SignUp = (props)=>{
    //ANCHOR - State Init
    const clientParams = useSelector(parameters);
    const fullnameRef = useRef();
    const emailRef = useRef(); 
    const passwordRef = useRef(); 
    const retypeRef = useRef();
    const scrollRef = useRef(); 
    const [signUpStep, setSignUpStep ] = useState(0); 
    const [fullname, setFullname ] = useState(""); 
    const [errorFullname, setErrorFullname ] = useState(false);
    const [gender, setGender ] = useState("L"); 
    const [email, setEmail] = useState(""); 
    const [errorEmail, setErrorEmail ] = useState(false); 
    const [phone, setPhone ] = useState("0"); 
    const [errorPhone, setErrorPhone] = useState(false);
    const [agree, setAgree]= useState(false);
    const [passwordState, setPasswordState] = useState({
        passwordVisible : false, 
        retypeVisible : false, 
        password : "", 
        retyped : "",
        errorPassword : false, 
        errorRetype : false
    })

    const [progress, setProgress] = useState({
        showProgress : false, 
        message : "Harap tunggu",
        showError : false, 
        showSuccess : false
    })
    
    const [ additionalData, setAdditionalData ] = useState({
        occupation : "",
        errorOccupation : false, 
        education : "", 
        errorEducation : false,
        identity : "", 
        errorIdentity : false,
        imageLoaded : false,
        imageUri : "",
        imageName : "", 
        imageMime : "", 
        errorImage : false
    }); 



    const educations = [
        { label : "Sekolah Dasar", id : "SD"} ,
        { label : "Sekolah Menengah Pertama", id : "SMP"} ,
        { label : "Sekolah Menengah Atas", id : "SMA"} ,
        { label : "Strata 1", id : "S1"} ,
        { label : "Strata 2", id : "S2"} ,
        { label : "Strata 3", id : "S3"} ,
        { label : "Diploma 1", id : "D1"} ,
        { label : "Diploma 2", id : "D2"} ,
        { label : "Diploma 3", id : "D3"} ,
        { label : "Diploma 4", id : "D4"} ,

    ]

    const _occupations = clientParams.daftar_pekerjaan.split(";");
    let occupations = []; 
    for(let i = 0 ; i < _occupations.length;i++){
        occupations.push({
            label : _occupations[i], 
            id : _occupations[i]
        })
    }
    
    const _identities = clientParams.daftar_identitas.split(";"); 
    let identities = []; 
    for(let x = 0; x < _identities.length; x++){
        identities.push({
            label : _identities[x], 
            id : _identities[x]
        })
    }


    const onPasswordIconClick = (which)=>{
        let param = { ...passwordState};
        if(which == 0) {
            param.passwordVisible = (param.passwordVisible ? false : true); 
            setPasswordState(param);
        } else {
            param.retypeVisible = (param.retypeVisible ? false : true ); 
            setPasswordState(param);
        }
    }

 
    useEffect(()=>{
        console.log(additionalData.education); 
    },[additionalData.education]); 


    //ANCHOR - Validate Input
    const validateInput = ()=>{

        if(fullname == ""){
            setErrorFullname(true); 
            fullnameRef.current.focus();
            return; 
        }

        let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        let regPass = /^(?=.*\d).{8,}$/; 

        if(email == "" || !regEmail.test(email)){
            setErrorEmail(true);
            emailRef.current.focus();
            return;
        }

        if(!regPass.test(passwordState.password)){
            let pass = { ...passwordState}; 
            pass.errorPassword = true; 
            setPasswordState(pass); 
            passwordRef.current.focus();
            return;
        }

        if(passwordState.password != passwordState.retyped){
            let pass = { ...passwordState}
            pass.errorRetype = true; 
            setPasswordState(pass); 
            retypeRef.current.focus();
            return;
        }

        if(!shouldUploadIdent()){
            let formData = new FormData(); 
            formData.append("fullname", fullname);
            formData.append("email", email); 
            formData.append("password", passwordState.password); 
            formData.append("gender", gender); 
            formData.append("phone", phone);
            let progressState = {...progress}; 
            progressState.showProgress = true;
            progressState.message = "Harap Tunggu";
            setProgress(progressState);
            executeRegister(formData);
        

        } else {
            setSignUpStep(1); 
            scrollRef.current.scrollTo({x:0, y:0});
        }
    }

    //ANCHOR - Should Upload Ident
    const shouldUploadIdent = ()=>{
        let shouldUpload = false;
        switch(parseInt(clientParams.id_tipe)){
            case 2:
                case 3:
                case 6:
                case 7:
                case 8:
                case 9:
                case 10:
                case 11:
                case 13:
                case 15:
                case 16:
                case 17:
                case 18:
                case 21:
                    shouldUpload = true;
                    break;
                default:
                    shouldUpload = false;
                    break;
        }

        return shouldUpload;
    }
    //ANCHOR - On Education Change
    const onEducationChange = (value)=>{
        let data = { ...additionalData} 
        data.education = value; 
        data.errorEducation = false;
        setAdditionalData(data);
    }
    //ANCHOR - On Occupation Change
    const onOccupationChange = (value)=>{
        let data = { ...additionalData }
        data.occupation = value; 
        data.errorOccupation = false; 
        setAdditionalData(data);
    }
    //ANCHOR - On Card Click 
    const onCardClick = async()=>{
        const document = await DocumentPicker.getDocumentAsync({
            type : "image/*"
        })

        console.log('document')
        console.log(document)
        // if(document.type == "success") {
            //console.log("Success"); 
            let filename = document.assets[0].name; 
            let mimeType = document.assets[0].mimeType; 
            let fileUri = document.assets[0].uri; 

            let data = { ...additionalData}; 
            data.imageUri = fileUri; 
            data.imageLoaded = true; 
            data.errorImage = false;
            data.imageName = filename; 
            data.imageMime = mimeType;
            setAdditionalData(data);
            //console.log('data')
            //console.log(data)
        // }

    }

    const onIdentityChange = (value)=>{
        let data = { ...additionalData } 
        data.identity = value; 
        data.errorIdentity = false; 
        setAdditionalData(data); 
    }
    
    //ANCHOR - SignupExtra 
    const onConfirmClick = ()=>{

        let data = { ...additionalData}
        if(additionalData.education == "") {
            data.errorEducation = true; 
            setAdditionalData(data)
            return;
        }
        if(additionalData.occupation == "") {
            data.errorOccupation = true; 
            setAdditionalData(data);
            return; 
        }

        // if(!additionalData.imageLoaded){
        //     data.errorImage = true; 
        //     setAdditionalData(data);
        //     return;
        // }

        if(additionalData.identity == "") {
            data.errorIdentity = true; 
            setAdditionalData(data); 
            return; 
        }

        let parameters = {
            fullname : fullname, 
            email : email, 
            gender : gender , 
            phone : phone, 
            education : additionalData.education, 
            occupation : additionalData.occupation, 
            card_type : additionalData.identity,
            password : passwordState.password,
            card_number : "0000000"
        }
        try {
            let fileObj = dataURLToFile(additionalData.imageUri , additionalData.imageName);

            let formData = new FormData(); 
            formData.append("fullname", parameters.fullname); 
            formData.append("email", email); 
            formData.append("gender", gender); 
            formData.append("phone", phone); 
            formData.append("education", additionalData.education);
            formData.append("occupation", additionalData.occupation);
            formData.append("card_type", additionalData.identity);
            formData.append("password", passwordState.password);
            formData.append("card_number", "000000"); 
            formData.append("id_card", fileObj); 
            let progressState = {...progress}; 
            progressState.showProgress = true;
            progressState.message = "Harap Tunggu";
            setProgress(progressState);
            executeRegister(formData);

            
        } catch(error){
            console.error(error.message);
        }
    }   

    //ANCHOR - On Error Click
    const onErrorClick = ()=>{
        let data = { ...progress }; 
        data.showError = false; 
        setProgress(data);
    }

    //ANCHOR - Execute Register
    const executeRegister = async(formData)=>{
        const response = await executePost(api.registration, formData);
        let progressState =  { ...progress};
        progressState.showProgress = false;
        //console.log(JSON.stringify(response)); 
        if(response.code == resCode.ok){
            progressState.showSuccess = true;
            progressState.message = response.msg;
            setProgress(progressState);
        } else {
            progressState.showError = true;
            progressState.message = response.msg;
            setProgress(progressState);
        }
    }

    //ANCHOR - On Success Click
    const onSuccessClick = ()=>{
        let progressState = { ...progress}; 
        progressState.showSuccess = false;
        setProgress(progressState);

        props.navigation.goBack(); 
    }

    const backToPageOne = ()=>{
        setAgree(false);
        setSignUpStep(0);
    }
    const onBackClick = ()=>{
        props.navigation.goBack();
    }
    
    return(
        <View style={ styles.container }>
            <Label 
                weight={"600"}
                size={16}
                text={"Daftar Akun Baru"}
                style={{
                    marginTop : 16,  
                    marginLeft : 16, 
                    marginBottom : 16
                }}
            />

            <View 
                style={{
                    height : 0.5,
                    backgroundColor : "silver",
                    width : "100%",
                    marginBottom : 16
                }}
            />

            <ScrollView
                ref={ scrollRef }
                showsVerticalScrollIndicator={false}
            >
                {
                    signUpStep == 0 && 
                    <>
                        <TextField 
                            ref={fullnameRef}
                            labelText="Nama Lengkap *"
                            marginLeft={16}
                            marginRight={16}
                            marginBottom={12}
                            onChange={(text)=>{ 
                                setFullname(text);
                                setErrorFullname(false);
                            }}
                            placeholder={"Ketik nama lengkap anda"}
                            error={ errorFullname }
                            errorText={"Harap masukkan nama lengkap anda"}
                        />
                        <Options
                            label={"Jenis Kelamin *"}
                            valueOne={"L"}
                            valueTwo={"P"}
                            labelOne={"Pria"}
                            labelTwo={"Wanita"}
                            checkedValue={ gender }
                            style={{
                                marginLeft : 16, 
                                marginRight : 16,
                                marginBottom : 12
                            }}
                            onChange={(value)=>{ setGender(value)}}
                        />
                        <TextField 
                            ref={ emailRef }
                            labelText="E-Mail *"
                            marginLeft={16}
                            marginRight={16}
                            marginBottom={12}
                            onChange={(text)=>{ 
                                setEmail(text);
                                setErrorEmail(false);
                            }}
                            placeholder={"Ketik email anda"}
                            error={ errorEmail }
                            errorText={"Harap masukkan email anda"}
                        />
                        <TextField 
                            labelText="No. Ponsel *"
                            marginLeft={16}
                            marginRight={16}
                            marginBottom={12}
                            onChange={(text)=>{ 
                                setPhone(text);
                                setErrorPhone(false);
                            }}
                            placeholder={"Nomor ponsel anda"}
                            error={ errorPhone }
                            type={"number"}
                            errorText={"Harap masukkan no. ponsel anda"}
                        />
                        <TextField 
                            ref={ passwordRef }
                            labelText="Buat Password *"
                            marginLeft={16}
                            marginRight={16}
                            marginBottom={12}
                            onChange={(text)=>{ 
                                let param = { ...passwordState}; 
                                param.password = text; 
                                param.errorPassword = false; 
                                setPasswordState(param);
                            }}
                            type={
                                passwordState.passwordVisible ? "text" : "password"
                            }
                            withIcon={ true }
                            icon={
                                passwordState.passwordVisible ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>
                            }
                            placeholder={"Min. 8-karakter alfanumerik"}
                            error={ passwordState.errorPassword }
                            errorText={"Minimal 8-karakter alfanumerik"}
                            onIconClick={()=>{ onPasswordIconClick(0)}}
                        />
                        <TextField 
                            ref={ retypeRef }
                            labelText="Ketik ulang Password *"
                            marginLeft={16}
                            marginRight={16}
                            marginBottom={16}
                            onChange={(text)=>{ 
                                let param = { ...passwordState }; 
                                param.retyped = text; 
                                param.errorRetype = false; 
                                setPasswordState(param);
                            }}
                            type={
                                passwordState.retypeVisible ? "text" : "password"
                            }
                            withIcon={ true }
                            icon={
                                passwordState.retypeVisible ? <VisibilityOffOutlinedIcon/> : <VisibilityOutlinedIcon/>
                            }
                            placeholder={"Ketik ulang password anda"}
                            error={ passwordState.errorRetype }
                            errorText={"Ketik ulang password anda"}
                            onIconClick={()=>{ onPasswordIconClick(1)}}
                        />

                        <Checklist 
                            style={{
                                marginLeft : 16, 
                                marginRight : 16, 
                                marginBottom : 12
                            }}
                            label={ "saya menyetujui syarat dan ketentuan yang berlaku"}
                            onChecked={(checked)=>{
                                setAgree(checked); 
                            }}
                            labelStyle={{ cursor : "pointer"}}
                            labelColor={"#2980b9"}
                            onLabelClick={()=>{
                                //TODO: On Label Click , openup url 
                                console.log('clientParams');
                                console.log(clientParams);
                                // let termsURL  = clientParams.url_privacy_policy;
                                window.open(clientParams.url_privacy_policy)
                            }}
                        />

                        <PushButton 
                            text={ shouldUploadIdent() ? "Selanjutnya" : "Daftar" } 
                            textWeight={"400"}
                            variant="contained"
                            style={{
                                marginLeft : 16, 
                                marginRight : 16,
                                marginBottom : 8, 
                                borderRadius : 8
                            }}
                            disabled={ !agree }
                            onClick={ validateInput }
                        />
                        <PushButton 
                            text={"Kembali"} 
                            textWeight={"400"}
                            variant="outlined"
                            style={{
                                marginTop : 16, 
                                marginLeft : 16, 
                                marginRight : 16,
                                marginBottom : 16, 
                                borderRadius : 8
                            }}
                            onClick={ onBackClick }
                        />
                    </>
                }
                {
                    signUpStep == 1 && 
                    <>
                        <ComboBox 
                            containerStyle={{marginBottom : 12 }}
                            style={{ minWidth : 350, borderRadius : 8  }}
                            options={ educations } 
                            label={"Pendidikan Terakhir"}
                            labelSize={14}
                            defaultValue={ educations[0].label }
                            value={ additionalData.education }
                            error={ additionalData.errorEducation }
                            errorText={"Harap pilih pendidikan terakhir anda"}
                            onChange={ onEducationChange }
                        />
                        {
                            clientParams.id == 1271 &&
                            <>
                                <ComboBox 
                                    style={{ minWidth : 350, borderRadius : 8 }}
                                    options={ occupations } 
                                    defaultValue={ occupations[0].label }
                                    value={ additionalData.occupation }
                                    label={"Prodi"}
                                    labelSize={14}
                                    error={ additionalData.errorOccupation }
                                    errorText={"Harap pilih pekerjaan terakhir anda"}
                                    onChange={ onOccupationChange }
                                />
                            </>
                        }
                        {
                            clientParams.id != 1271 &&
                            <>
                                <ComboBox 
                                    style={{ minWidth : 350, borderRadius : 8 }}
                                    options={ occupations } 
                                    defaultValue={ occupations[0].label }
                                    value={ additionalData.occupation }
                                    label={"Pekerjaan"}
                                    labelSize={14}
                                    error={ additionalData.errorOccupation }
                                    errorText={"Harap pilih pekerjaan terakhir anda"}
                                    onChange={ onOccupationChange }
                                />
                            </>
                        }
                        <ComboBox 
                            style={{ minWidth : 350, borderRadius : 8 }}
                            options={ identities } 
                            value={ additionalData.identity }
                            label={"Jenis Identitas"}
                            labelSize={14}
                            error={ additionalData.errorIdentity }
                            errorText={"Harap pilih jenis identitas anda"}
                            onChange={ onIdentityChange }
                        />
                        <Label
                            size={14}
                            weight={"400"}
                            style={{
                                marginTop : 16,
                                marginBottom : 8
                            }}
                            text={"Kartu Identitas"}
                        />
                        <View style={ styles.imageContainer}>
                            <Pressable
                                onPress={ onCardClick }
                            >
                                {
                                    !additionalData.imageLoaded && 
                                    <CardPlaceholder />
                                }
                                {
                                    additionalData.imageLoaded && 
                                    <img
                                        src={ additionalData.imageUri }
                                        style={{
                                            width : 250,
                                            height : "auto", 
                                            borderRadius : 8
                                        }}
                                    />
                                }
                                
                            </Pressable>
                        </View> 
                        {
                            additionalData.errorImage && 
                            <View style={{flexDirection : "row-reverse"}}>
                                <Label
                                    size={12}
                                    text={"Harap sertakan kartu identitas anda yang terkait"}
                                    color={"#d63031"}
                                />
                            </View>
                        }
                        <PushButton 
                            text={"Daftar"} 
                            textWeight={"400"}
                            variant="contained"
                            style={{
                                marginTop : 16, 
                                marginBottom : 8, 
                                borderRadius : 8
                            }}
                            onClick={ onConfirmClick }
                        />
                        <PushButton 
                            text={"Kembali"} 
                            textWeight={"400"}
                            variant="outlined"
                            style={{
                                marginTop : 16, 
                                marginBottom : 16, 
                                borderRadius : 8
                            }}
                            onClick={ backToPageOne }
                        />
                    </>
                }
            </ScrollView>
            <ProgressDialog 
                open={ progress.showProgress }
                message={ progress.message }
            />
            <ErrorDialog
                open={ progress.showError}
                message={ progress.message }
                label={"OK"}
                onClick={ onErrorClick }
            />
            <SuccessDialog
                open={ progress.showSuccess}
                message={ progress.message }
                label={"OK"}
                onClick={ onSuccessClick }
            />
        </View>
    )
}

const styles = StyleSheet.create({
    container : {
        flex : 1,
        backgroundColor : "white",
        alignItems : "center"
    },
    rightSide : {
        width : 350, 
        backgroundColor : "white"
    },
    imageContainer : {
        borderStyle : "dashed", 
        height : 220, 
        alignItems : "center", 
        justifyContent : "center",
        borderWidth : 0.5,
        backgroundColor : "#ecf0f1",
        borderRadius : 8,
        marginBottom : 8
    }
})

export { SignUp }