import React, { useState, useEffect, useRef } from 'react'; 
import { View, StyleSheet, FlatList,Pressable, Dimensions , TextInput} from 'react-native'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog,NewsDetailDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams, clientAdditionalParams } from '../reducers/parameterSlice';
import { Label, PushButton, PushChip } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { Paper, Card, CircularProgress, CardHeader,CardMedia, ButtonBase, Tabs, Tab } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';



const DonationStore = (props)=>{
    const isMounted = useIsMounted(); 
    const clientParams = useSelector(parameters); 
    const clientConfiguration = useSelector(clientAdditionalParams); 
    const nf = new Intl.NumberFormat(); 
    const poinData = [
        {
            name : "Poin 1", 
            value : clientConfiguration.poin1,
            label : "Rp. "+nf.format(clientConfiguration.poin1)
        },
        {
            name : "Poin 2", 
            value : clientConfiguration.poin2,
            label : "Rp. "+nf.format(clientConfiguration.poin2)
        },
        {
            name : "poin 3", 
            value : clientConfiguration.poin3,
            label : "Rp. " +nf.format(clientConfiguration.poin3)
        },
        {
            name : "Poin 4", 
            value : clientConfiguration.poin4,
            label : "Rp. "+nf.format(clientConfiguration.poin4)
        },
        {
            name : "Poin 5", 
            value : clientConfiguration.poin5,
            label : "Rp. "+nf.format(clientConfiguration.poin5)
        }
    ]

    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });
    const pageRef = useRef({
        page : 1, 
        show : 0,
        total : 0
    })

    const paymentURLRef = useRef(); 
    
    const [progressState, setProgressState ] = useState({
        showProgressDialog : false, 
        showProgress : true, 
        showError : false, 
        showSuccess : false, 
        message : ""
    })

    const [sort, setSort ] = useState({
        by : "judul", 
        type : "asc"
    })

    const searchInputRef = useRef(); 
    const [tabValue, setTabValue]= useState(0); 
    const [bookData, setBookData] = useState([]); 
    const [renderedData, setRenderedData ] = useState([]); 
    const [keyword, setKeyword] = useState("");
    const [pageState, setPageState] = useState({
        page : 1, 
        show : 0, 
        total : 0
    })

    const [selectedPoin, setSelectedPoin] = useState(""); 

    const handleTabChange = (event, value)=>{
        setTabValue(value); 
    }
    const [loadPayment, setLoadPayment] = useState(false); 
    const [paymentTypes,  setPaymentTypes] = useState([]); 
    const [selectedPaymentType, setSelectedPaymentType] = useState({ type : ""}); 
    
    useEffect(()=>{ 
        //console.log(JSON.stringify(poinData)); 
        pageRef.current.page = 1; 
        pageRef.current.total = 0 ;
        pageRef.current.show = 0; 
        let _progressState = { ...progressState } 
        _progressState.showProgress = true; 
        _progressState.message = "Memuat Data";
        setProgressState(_progressState); 
        fetchStore();
    },[sort.by, sort.type])

    const fetchStore = async()=>{
        let formData = new FormData(); 
        //console.log(pageState.total);
        formData.append("page", pageRef.current.page) ;
        formData.append("limit", 100); 
        formData.append("sortby", sort.by); 
        formData.append("sorttype", sort.type); 
        if(searchInputRef.current != null){
            formData.append("keyword", searchInputRef.current.value); 
        }


        const response = await executePost(api.storeContent, formData); 
        let _progressState = { ...progressState } 
        if(response.code = resCode.ok){
            _progressState.showProgress = false; 
            _progressState.showProgressDialog = false; 
            setProgressState(_progressState); 
            pageRef.current.total = response.total ;
            pageRef.current.show += response.show;

            if(response.show > 0 ) {
                let _bookData;
                if(pageRef.current.page == 1){
                    _bookData = [...response.data]; 
                } else {
                    _bookData = [...bookData, ...response.data];
                }
                
                setBookData(_bookData);  
                let _renderedData = [..._bookData]
                let mod = _bookData.length % 2 ; 
                if(mod > 0 ) {
                    let add_factor = 2 - mod; 
                    for(let i = 0; i< add_factor ; i++ ) {
                        _renderedData.push({dummy : i}); 
                    }
                }
                setRenderedData(_renderedData); 
            }
        } else {
            if( response.code != resCode.error){
                _progressState.showError = true; 
                _progressState.message = response.msg; 
                setProgressState(_progressState); 

            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }
    //ANCHOR - FETCH MORE DATA
    const fetchMoreData = ()=>{
        
        if(pageRef.current.show < pageRef.current.total){
            let _progressState = {...progressState} 
            _progressState.showProgressDialog = true; 
            _progressState.message = "Memuat data lanjutan"; 
            setProgressState(_progressState); 

            pageRef.current.page += 1; 
            fetchStore();
        }
    }

    const renderItem = ({item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
            return(
                <Pressable style={{
                    cursor : "pointer", 
                    flex : 1, 
                    flexDirection : "row", marginBottom : 12 }}
                    //onPress={()=>{ onContentClick(item)}}
                >
                    <img 
                        src={ item.img_file }
                        style={{
                            width : 120, 
                            borderRadius : 12
                        }}
                    />

                    <View style={{flex : 1, marginLeft : 16 }}>
                        <Label 
                            size={14}
                            weight={"600"}
                            text={item.judul }
                            style={{
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label
                            size={12}
                            weight={"600"}
                            color={"#30336b"}
                            text={item.penulis}
                            style={{
                                marginTop : 8 ,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 1, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label
                            size={10}
                            weight={"400"}
                            color={"#2980b9"}
                            style={{marginTop : 16}}
                            text={"Harga"}
                        />
                        <Label 
                            size={14}
                            weight={"600"}
                            color={"#2980b9"}
                            style={{marginTop : 2}}
                            text={ item.harga_desimal }
                        />
                        <View style={{
                            flexDirection: "row-reverse", 
                            position : "absolute", 
                            width : "90%", 
                            bottom : 0
                            }}>
                            <PushButton 
                                variant={"contained"}
                                text={"Beli"}
                                textSize={12} 
                                style={{ borderRadius : 8, paddingLeft : 4, paddingRight : 4 }}
                            />
                            <PushButton 
                                variant={"outlined"}
                                text={"Masuk Keranjang"}
                                textSize={12} 
                                style={{ borderRadius : 8, paddingLeft : 4, paddingRight : 4 , marginRight : 8 }}
                                onClick={()=>{
                                    insertTroliClick(item);
                                }}
                            />
                        </View>
                    </View>
                </Pressable>
            )
        } else {
            return(
                <View style={{flex : 1}}>
                </View>
            )
        }
    }

    //ANCHOR - INSERT TROLI CLICK
    const insertTroliClick = (item)=>{        
        let _progressState = {...progressState} 
        _progressState.showProgressDialog = true; 
        _progressState.message = "Memasukkan kedalam keranjang"; 
        setProgressState(_progressState); 
        insertTroli(item.id); 
    }

    //ANCHOR - INSERT TROLI REQUEST 
    const insertTroli = async(id)=>{
        let formData = new FormData(); 
        formData.append("id_konten", id); 
        formData.append("qty", 1);
        
        const response = await executePost(api.setTroli, formData); 
        let _progressState = { ...progressState} 
        _progressState.showProgressDialog = false; 
        if(response.code == resCode.ok){
            setProgressState(_progressState)
            props.onAddCart(response.troli_donasi); 
        } else {
            if(response.code != resCode.error){
                _progressState.showError = true ;
                _progressState.message = response.msg; 
                setProgressState(_progressState)
            } else {
                //ANCHOR - FORCE LOGOUT
                window.postMessage({command : "FORCE_LOGOUT"}, "*");

            }
        }
    }

    //ANCHOR - GET PAYMENT TYPES
    const getPaymentMethod = async()=>{
        const response = await executeGet(api.paymentTypes); 
        if(response.code == resCode.ok){
            if(isMounted()){
                setLoadPayment(false); 
                setPaymentTypes(response.data); 

            }
        }
        //console.log("PAYMENT METHOD"); 
        //console.log(JSON.stringify(response)); 
    }
    
    //ANCHOR - RENDER PAYMENT TYPES
    const renderPaymentTypes = ({item, index, separators})=>{
        return(
            <ButtonBase
                sx={{
                    borderRadius : 2, 
                    flex : 1, 
                    justifyContent : "left"
                }}
                onClick={()=>{
                    setSelectedPaymentType(item);
                }}
            >
                <View style={{
                    flexDirection : "row", 
                    alignItems : "center", 
                    paddingTop : 8, 
                    paddingBottom : 8 ,
                    flex :1 ,
                    borderWidth : (selectedPaymentType.type == item.type ? 1 : 0) , 
                    borderRadius : 8, 
                    borderColor : "silver"
                    }}>
                    <img 
                        src={ item.logo}
                        style={{marginLeft : 16, marginRight : 8 , width : 64, height : 64 }}
                    />  
                    <View style={{flex : 1, marginRight : 16, alignItems : "flex-start" }}>
                        <Label 
                            size={14}
                            text={item.nama}
                            weight={"400"}
                        />
                        <Label
                            size={12}
                            text={item.deskripsi} 
                            weight={"400"}
                            color={"#7f8c8d"}
                        />
                        <Label 
                            size={12}
                            weight={"400"}

                            text={ item.fee != "0" ? ("(+Rp. " + nf.format(parseInt(item.fee)) + " biaya admin)") : ("(+" + nf.format(parseInt(item.persen)) +"% biaya transaksi)")}
                        />
                    </View>
                    {
                        selectedPaymentType.type == item.type && 
                        <CheckCircleOutlinedIcon 
                            sx={{color : "#2ecc71", width : 32, height : 32, mr : 2}}
                        />
                    }

                </View>
            </ButtonBase>
        )
    }

    //ANCHOR - CHECKOUT POIN DONASI 
    const checkOutPoinDonasi = ()=>{
        try {
            let poin = parseInt(selectedPoin); 
            let fee = selectedPaymentType.fee != "0" ? 
            (parseInt(selectedPaymentType.fee)) : 
            ( (parseInt(selectedPaymentType.persen) / 100) * poin ) 
            let total = poin + fee; 
            
            let formData = new FormData(); 
            formData.append("id_payment", selectedPaymentType.id); 
            formData.append("nilai", poin);
            formData.append("provider", selectedPaymentType.provider);
            formData.append("fee", fee); 
            let _progressState = { ...progressState}; 
            _progressState.showProgressDialog = true; 
            _progressState.message = "Checkout Process"; 
            setProgressState(_progressState); 
            checkOutTokenDonasi(formData); 

            
        } catch(error){
            console.error(error.message); 
        }
        
    }

    //ANCHOR - CHECKOUT TOKEN DONASI 
    const checkOutTokenDonasi = async(formData)=>{
        const response = await executePost(api.checkOutTokenDonasiPoin, formData); 
        let _progressState = { ...progressState} 
        _progressState.showProgressDialog = false; 
        if(response.code == resCode.ok){
            if(isMounted()){
                console.log(JSON.stringify(response)); 
                paymentURLRef.current = response; 
                _progressState.showSuccess = true; 
                _progressState.message = "Checkout berhasil!\nIkuti langkah selanjutnya"; 
                setProgressState(_progressState); 
                setSelectedPaymentType({type : ""});
                setSelectedPoin(""); 
            }


        } else {
            if(response.code != resCode.error){
                if(isMounted()){
                    _progressState.showError = true; 
                    _progressState.message = response.msg; 
                    setProgressState(_progressState); 
                }
            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }

    //ANCHOR - HANDLE CHECKOUT POIN SUCCESS
    const handleCheckoutSuccess = ()=>{
        let _progressState = {...progressState }
        _progressState.showSuccess = false;  
        setProgressState(_progressState); 
        window.electron.sendMessage("OPEN_URL", paymentURLRef.current.url); 
    }

    //ANCHOR - RENDER RETURN
    return(
        <ThemeProvider theme={ theme }>
            {
                progressState.showProgress && 
                <Progress 
                    text={ progressState.message } 
                />
            }
            {
                !progressState.showProgress && 
                <View style={{flex : 1}}>
                    <Tabs value={tabValue} style={{marginLeft : 16, marginRight : 16 }} onChange={handleTabChange }>
                        <Tab label={"Donasi Buku"} />
                        <Tab label={"Donasi Poin"} />
                    </Tabs>
                    {
                        (tabValue == 0 && bookData.length > 0 && !progressState.showProgress ) && 
                        <FlatList 
                            ListHeaderComponent={
                                <View style={{height : 64, width : "100%", flexDirection : "row", alignItems : "center", backgroundColor : "white"}}>
                                    <TextInput 
                                        ref={ searchInputRef}
                                        style={{
                                            borderRadius : 8,
                                            borderColor : "silver", 
                                            borderWidth : 0.5, 
                                            outline : "none",
                                            paddingLeft : 12, 
                                            paddingRight : 12, 
                                            paddingTop : 8, 
                                            paddingBottom : 8,
                                            fontSize : 14, 
                                            flex : 1, 
                                            marginLeft : 12, 
                                            fontFamily : "Poppins",
                                            maxWidth : 350
                                        }}
                                        placeholder={"Pencarian buku donasi"}
                                        placeholderTextColor={"silver"}
                                        value={keyword}
                                        onChange={(e)=>{
                                            setKeyword(e.nativeEvent.text);
                                        }}
                                        onKeyPress={(e)=>{
                                            if(e.nativeEvent.key == "Enter"){
                                                pageRef.current.page = 1; 
                                                pageRef.current.show = 0;
                                                pageRef.current.total = 0;
                                                let _progressState = { ...progressState}; 
                                                _progressState.showProgress = true; 
                                                _progressState.message = "Pencarian"; 
                                                setProgressState(_progressState); 
                                                fetchStore();
                                            }
                                        }}
                                        
                                    />
                                    <Label
                                        size={12}
                                        text={"Urutkan: "}
                                        style={{marginLeft : 16 }}
                                    />

                                    <PushChip 
                                        label={"Judul A~Z"} 
                                        color={'primary'}
                                        style={{marginLeft : 12}}
                                        variant={ (sort.by == "judul" && sort.type == "asc") ? "filled" : "outlined"}
                                        onClick={()=>{ 
                                            setSort ({
                                                by : "judul", 
                                                type : "asc"
                                            });
                                        }}
                                    />

                                    <PushChip 
                                        label={"Judul Z~A"} 
                                        color={'primary'}
                                        style={{marginLeft : 12}}
                                        variant={ (sort.by == "judul" && sort.type == "desc") ? "filled" : "outlined"}
                                        onClick={()=>{ 
                                            setSort({
                                                by : "judul", 
                                                type : "desc"
                                            });
                                        }}
                                    />
                                    <PushChip 
                                        label={"Harga Rendah~Tinggi"} 
                                        color={'primary'}
                                        style={{marginLeft : 12}}
                                        variant={ (sort.by == "harga" && sort.type == "asc") ? "filled" : "outlined"}
                                        onClick={()=>{ 
                                            setSort ({
                                                by : "harga", 
                                                type : "asc"
                                            });
                                        }}
                                    />
                                    <PushChip 
                                        label={"Harga Tinggi~Rendah"} 
                                        color={'primary'}
                                        style={{marginLeft : 12}}
                                        variant={ (sort.by == "harga" && sort.type == "desc") ? "filled" : "outlined"}
                                        onClick={()=>{ 
                                            setSort ({
                                                by : "harga", 
                                                type : "desc"
                                            }); 
                                        }}
                                    />

                                </View>
                            }
                            contentContainerStyle={{ marginLeft : 16, marginRight : 16, marginTop : 16, marginBottom : 16  }}
                            keyExtractor={(i, x)=>"book_index_"+x} 
                            renderItem={ renderItem }
                            data={renderedData}
                            numColumns={2} 
                            stickyHeaderIndices={[0]}
                            showsVerticalScrollIndicator={false}
                            onEndReachedThreshold={ 0.5 }
                            onEndReached={ fetchMoreData }
                        />
                    }
                    {
                        (tabValue == 1 && poinData.length > 0 && !progressState.showProgress) && 
                        <View style={{marginTop : 16 , marginLeft : 16, marginRight : 16}}>
                            <Label 
                                size={14}
                                weight={"600"}
                                text={"Donasikan sejumlah poin untuk perpustakaan"}
                            />
                            <Label
                                size={14}
                                weight={"400"}
                                text={"Poin ini akan digunakan untuk membeli buku/konten yang dibutuhkan"}
                            />
                            <View style={{ marginTop : 16 , marginBottom : 16 , height : 0.5, backgroundColor : "silver", width : "100%"}} />
                            <Label
                                size={16}
                                weight={"600"}
                                color={"#2980b9"}
                                text={"Pilih nilai poin"}
                            />
                            <View style={{ flexDirection : "row", alignItems : "center", marginTop : 12 }}> 
                            {
                                poinData.map((i, x)=>(
                                    <PushChip
                                        key={i.name}
                                        label={i.label}
                                        color={'primary'}
                                        style={{marginLeft : (x == 0 ? 0 : 8) }}
                                        onClick={()=>{
                                            if(isMounted()){
                                                setSelectedPaymentType({type : ""})
                                                setSelectedPoin(i.value);
                                                setLoadPayment(true); 
                                                getPaymentMethod();
                                            }
                                        }}
                                        variant={selectedPoin == i.value ? "filled" : "outlined"}
                                        textSize={14}
                                        textPaddingLeft={16}
                                        textPaddingRight={16}
                                    />
                                ))
                            }
                            </View>
                            {
                                selectedPoin != ""  &&
                                <>
                                <Label
                                    size={16}
                                    weight={"600"}
                                    color={"#2980b9"}
                                    text={"Pilih Metode Pembayaran"}
                                    style={{marginTop : 16}}
                                />
                                {
                                    loadPayment && 
                                    <View style={{flexDirection : "row", alignItems : "center", marginTop : 16}}>
                                        <CircularProgress />
                                        <Label
                                            size={14}
                                            text={"Fetching payment method"} 
                                            color={"#2980b9"}
                                            style={{marginLeft : 12 }}
                                        />
                                    </View>
                                }
                                {
                                    (!loadPayment && paymentTypes.length > 0) &&
                                    <FlatList 
                                        contentContainerStyle={{marginTop : 8 }}
                                        data={paymentTypes}
                                        numColumns={2}
                                        renderItem={renderPaymentTypes}
                                        keyExtractor={(i,x)=>"payment_idx_"+x}
                                    />
                                }
                                {
                                    selectedPaymentType.type != "" && 
                                    <>
                                        <Label
                                            size={16}
                                            weight={"600"}
                                            color={"#2980b9"}
                                            text={"Total Transaksi"}
                                            style={{marginTop : 16}}
                                        />
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 16 }}>
                                            <Label  
                                                size={14}
                                                text={"Donasi Poin"}
                                                style={{flex : 0.2}}
                                            />
                                            <Label 
                                                size={14}
                                                weight={"600"}
                                                text={"Rp. "+ nf.format(selectedPoin)} 
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 8 }}>
                                            <Label 
                                                size={14}
                                                text={"Pembayaran menggunakan"}
                                                style={{flex : 0.2}}
                                            />
                                            <Label 
                                                size={14}
                                                weight={"600"}
                                                text={ selectedPaymentType.deskripsi} 
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 8 }}>
                                            <Label 
                                                size={14}
                                                text={"Biaya admin"}
                                                style={{flex : 0.2}}
                                            />
                                            <Label 
                                                size={14}
                                                weight={"600"}
                                                text={ selectedPaymentType.fee != "0" ? ("Rp. " + nf.format(selectedPaymentType.fee)) : (nf.format(parseInt(selectedPaymentType.persen)) +"% dari total poin")} 
                                                style={{flex : 0.7}}
                                            />
                                        </View>
                                        <View style={{width : "100%" , height : 0.5, backgroundColor : "silver", marginTop : 8}} />
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 8 }}>
                                            <Label 
                                                size={14}
                                                text={"Grand Total"}
                                                weight={"600"}
                                                color={"#2980b9"}
                                                style={{flex : 0.2}}
                                            />
                                            <Label 
                                                size={14}
                                                weight={"600"}
                                                color={"#2980b9"}
                                                text={ 
                                                    (selectedPaymentType.fee != "0") ? 
                                                    ("Rp. " + nf.format((parseInt(selectedPoin) + parseInt(selectedPaymentType.fee)))) :
                                                    ("Rp. " + nf.format( (parseInt(selectedPoin) + (parseInt(selectedPaymentType.persen) / 100 * parseInt(selectedPoin) ) ) )  )
                                                } 
                                                style={{flex : 0.7}}
                                            />
                                            
                                            
                                        </View>
                                        <View style={{flexDirection : "row", alignItems : "center", marginTop : 4 }}>
                                            <PushChip 
                                                label={"Checkout"} 
                                                color={'primary'}
                                                variant={"filled"}
                                                style={{flex : 0.3 }}
                                                textWeight={"600"}
                                                onClick={ checkOutPoinDonasi }
                                            />
                                        </View>



                                    </>
                                }

                                </>
                            }

                        </View>
                    }


                </View>
            }
            <ErrorDialog 
                open={ progressState.showError }
                message={ progressState.message } 
                label={"OK"}
                onClick={()=>{
                    let _progressState = { ...progressState } 
                    _progressState.showError = false; 
                    setProgressState(_progressState); 
                    
                }}
            />
            <ProgressDialog 
                open={progressState.showProgressDialog}
                message={progressState.message}
            />
            <SuccessDialog 
                open={ progressState.showSuccess}
                message={progressState.message }
                label={"Cara Bayar"}
                onClick={ handleCheckoutSuccess }
            />
            
        </ThemeProvider>
    )

}

export { DonationStore }; 