import  React, { useState, useRef, useEffect } from 'react'; 
import { IconButton } from '@mui/material';
import { randomId  } from '../utilities/System';
import { IconMessage } from '../assets/Icons';
import { Position, Tooltip, PrimaryButton, Button } from '@react-pdf-viewer/core'; 
import { Label, TextField } from './FormComponents';

const PageCanvas = React.forwardRef((props, innerRef)=>{

    //ANCHOR - 001 - STATE INIT 
    const [tempHighlight, setTempHighlight] = useState({
        rect : {x : 0 , y : 0, w : 0 , h : 0 }
    })

    const [showNoteIcon, setShowNoteIcon] = useState(false); 
    const [showNoteArea, setShowNoteArea] = useState(false); 
    const [highlights, setHighlights ] = useState(props.highlights); 

    //ANCHOR - 002 - REF INIT 
    const canvasRef = useRef(); 
    const contextRef = useRef();
    const noteRef = useRef(); 
    const isDrawingRef = useRef(false); 
    const canvasOffSetX = useRef();
    const canvasOffSetY = useRef();
    const startX = useRef();
    const startY = useRef();

    //ANCHOR - 003 - USE EFFECTS HOOKS

    useEffect(()=>{
        //ANCHOR - 003 - A - CANVAS REF INIT
        const canvas = canvasRef.current;
        canvas.width =  canvas.clientWidth;
        canvas.height = canvas.clientHeight;

        const context = canvas.getContext("2d");
        context.lineCap = "round";
        context.fillStyle = "rgba(255, 242, 0,0.3)"; 
        context.lineWidth = 2;
        contextRef.current = context;

        const canvasOffSet = canvas.getBoundingClientRect();
        canvasOffSetX.current = canvasOffSet.top;
        canvasOffSetY.current = canvasOffSet.left;
        if (props.renderPageProps.canvasLayerRendered) {        
            props.renderPageProps.markRendered(props.renderPageProps.pageIndex);
        }
        redrawCanvas();
    },[props.renderPageProps.canvasLayerRendered])

    useEffect(()=>{
        //ANCHOR - 003 - B - REDRAW WHEN HIGHLIGHT CHANGED
        redrawCanvas();
    },[props.highlights.length]); 

    useEffect(()=>{
        //ANCHOR - 003 - C - REDRAW WHEN AREA MODE CHANGED
        if(!props.areaMode){
            redrawCanvas();
        }
    },[props.areaMode])

    //ANCHOR - 004 - REDRAW FUNCTION 
    const redrawCanvas = ()=>{
        contextRef.current.clearRect(0, 0, canvasRef.current.clientWidth, canvasRef.current.clientHeight);
        if(props.highlights.length > 0) {
            props.highlights.map((i,x)=>{
                if(i.hasOwnProperty("pageIndex")){
                    if(i.pageIndex == props.renderPageProps.pageIndex) {
                        if(contextRef.current != null){
                            let scale = props.renderPageProps.scale; 
                            let scale_factor = scale / i.scale; 
                            contextRef.current.fillRect( (i.rect.x * scale_factor ), (i.rect.y * scale_factor), (i.rect.w * scale_factor), (i.rect.h * scale_factor));
                        } 
                    }
                }
            })
        }
    }

    //ANCHOR - 005 - CANCEL AREA HIGHLIGHT 
    const cancelAreaHighlight = ()=>{
        contextRef.current.clearRect(tempHighlight.rect.x, tempHighlight.rect.y, tempHighlight.rect.w, tempHighlight.rect.h);
        setTempHighlight({
            rect : {x : 0 , y : 0, w : 0 , h : 0 }
        })
        setShowNoteArea(false); 
    }

    //ANCHOR - 006 - ADD AREA HIGHLIGHT 
    const addAreaHighlight = ()=>{
        let noteContent = noteRef.current.value;  
        if(noteContent == ""){
            return; 
        }

        let area_highlight = { ...tempHighlight , content : noteContent } 
        console.log(JSON.stringify(area_highlight));
        const dpr = window.devicePixelRatio ; 
        
        let doc = props.renderPageProps.canvasLayer.children.props.canvasLayerRef.current.ownerDocument;  
        
        let newCanvas = doc.createElement("canvas"); 
        newCanvas.width = tempHighlight.rect.w; 
        newCanvas.height = tempHighlight.rect.h; 
        
        const newCanvasContext = newCanvas.getContext("2d"); 
        if(newCanvasContext == null){
            return; 
        }

        newCanvasContext.drawImage(
            props.renderPageProps.canvasLayer.children.props.canvasLayerRef.current,
            tempHighlight.rect.x * dpr,
            tempHighlight.rect.y * dpr,
            tempHighlight.rect.w * dpr,
            tempHighlight.rect.h * dpr ,
            0,
            0,
            newCanvas.width,
            newCanvas.height
        );
        let imageURL = newCanvas.toDataURL("image/png"); 
        area_highlight.quote = imageURL; 
        setHighlights([...highlights, area_highlight]); 
        props.highlightArea(area_highlight); 
        contextRef.current.clearRect(tempHighlight.rect.x, tempHighlight.rect.y, tempHighlight.rect.w, tempHighlight.rect.h);
        setTempHighlight({
            rect : {x : 0 , y : 0, w : 0 , h : 0 }
        })
        setShowNoteArea(false); 
    }

    //ANCHOR - 007 - MOUSE DOWN 
    const onMouseDown = ({nativeEvent})=>{
        nativeEvent.preventDefault();
        nativeEvent.stopPropagation();
    
        startX.current = nativeEvent.offsetX;
        startY.current = nativeEvent.offsetY; 
        setShowNoteIcon(false); 
        setShowNoteArea(false); 
        isDrawingRef.current = true; 
    }
    
    //ANCHOR - 008 - MOUSE MOVE 
    const onMouseMove = ({nativeEvent})=>{
        if (!isDrawingRef.current) {
            return;
        }
        nativeEvent.preventDefault();
        nativeEvent.stopPropagation();
        const newMouseX = nativeEvent.offsetX;
        const newMouseY = nativeEvent.offsetY;
        const rectWidht = newMouseX - startX.current;
        const rectHeight = newMouseY - startY.current;
        contextRef.current.beginPath(); 
        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        contextRef.current.fillRect(startX.current, startY.current, rectWidht, rectHeight); 
    }

    //ANCHOR - 009 - MOUSE UP 
    const onMouseUp = ({nativeEvent})=>{
        if(isDrawingRef.current){
            isDrawingRef.current = false;
            const newMouseX = nativeEvent.offsetX;
            const newMouseY = nativeEvent.offsetY;
            const rectWidht = newMouseX - startX.current;
            const rectHeight = newMouseY - startY.current;
            let highlightId = randomId(5);  
            let temp = {
                id : highlightId, 
                type : "area", 
                pageIndex : props.renderPageProps.pageIndex, 
                scale : props.renderPageProps.scale, 
                rect : {
                    x : startX.current, 
                    y : startY.current,  
                    w : rectWidht, 
                    h : rectHeight
                },
                highlightAreas : [
                    {
                        height : rectHeight, 
                        left : startX.current, 
                        pageIndex : props.renderPageProps.pageIndex, 
                        top : startY.current, 
                        width : rectWidht
                    }
                ]
            }
            setTempHighlight(temp); 
            setShowNoteIcon(true);
        }
    }

    
    //ANCHOR - 010 - VIEW RETURN
    return(
        <>
            {
                ( showNoteIcon && props.areaMode ) && 
                <div
                    style={{
                        background: '#eee',
                        display: ( showNoteIcon ? 'flex' :'none'),
                        position: 'absolute',
                        borderRadius : 8, 
                        left: tempHighlight.rect.x,
                        top: parseInt(tempHighlight.rect.y + tempHighlight.rect.h), 
                        transform: 'translate(0, 8px)',
                        zIndex : (showNoteIcon ? 4 : 0 ),  
                    }}
                >
                    <Tooltip
                        position={Position.TopCenter}
                        target={
                            <IconButton
                                onClick={()=>{ 
                                    setShowNoteIcon(false);
                                    setShowNoteArea(true);  
                                }}
                            >
                                <IconMessage width={24} height={24} />
                            </IconButton>
                        }
                        content={() => <Label style={{ width: '120px' }} text={"Tambah Catatan"} size={12} />}
                        offset={{ left: 0, top: -8 }}
                    />
                </div>
            }
            {
                showNoteArea && 
                <div
                    style={{
                        background: '#fff',
                        border: '1px solid rgba(0, 0, 0, .3)',
                        borderRadius: '2px',
                        padding: '8px',
                        position: 'absolute',
                        left: tempHighlight.rect.x,
                        top: tempHighlight.rect.y + tempHighlight.rect.h,
                        zIndex: 4,
                    }}
                >
                    <div>
                        <textarea
                            ref={ noteRef }
                            rows={3}
                            style={{
                                border: '1px solid rgba(0, 0, 0, .3)',
                            }}
                        ></textarea>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '8px',
                        }}
                    >
                        <div style={{ marginRight: '8px' }}>
                            <PrimaryButton onClick={ addAreaHighlight }>Add</PrimaryButton>
                        </div>
                        <Button onClick={ cancelAreaHighlight }>Cancel</Button>
                    </div>
                </div>
            }
            {props.renderPageProps.canvasLayer.children}
            {props.renderPageProps.annotationLayer.children}
            {props.renderPageProps.textLayer.children}
            <canvas
                id={"canvasIdx"+ props.renderPageProps.pageIndex}
                ref={ canvasRef }
                style={{
                    //alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    //justifyContent: 'center',
                    left: 0,
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    zIndex : (props.areaMode ? 3 : 0), 
                    cursor : (props.areaMode ? "crosshair" : "default")
                }}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
            ></canvas>
        </>
    )
})


export { PageCanvas }