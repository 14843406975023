import React, { useState } from 'react'; 
import { View, StyleSheet, Pressable } from 'react-native';
import { IconHome, IconNews, IconBag, IconFolder, IconUser,IconAppIntegrate, IconCart, IconTransaction } from '../assets/Icons'; 
import { Label } from './FormComponents';
import { useSelector } from 'react-redux'
import { parameters } from '../reducers/parameterSlice';
import { Badge } from '@mui/material';


const ButtonHome = (props)=>{
    const [ hover, setHover ] = useState(false); 
    const clientParameters = useSelector(parameters); 

    const styles = StyleSheet.create({
        default : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12
        },
        hovered : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12,
            backgroundColor : "#f5f6fa"
        }
    })


    return(
        <Pressable
            style={ props.style }
            onPress={ props.onClick }

        >
            <View style={{
                  flexDirection : "row", 
                  alignItems : "center", 
                  paddingLeft : 24, 
                  paddingRight : 8,
                  paddingTop : 12, 
                  paddingBottom : 12,
                  backgroundColor : (props.hovered && !props.active ? "#f5f6fa" : (props.active ? clientParameters.warna_header : null))
                }}
                onMouseEnter={ props.onMouseEnter }
                onMouseLeave={ props.onMouseLeave }
            >
                <IconHome 
                    width={24}
                    height={24}
                    color={ (props.active ? "white" : props.color )}
                />
                <Label 
                    size={14}
                    weight={"600"}
                    text={"Beranda"}
                    color={(props.active ? "white" : props.color )}
                    style={{marginLeft : 12 }}
                />
            </View>
        </Pressable>
    )
}

const ButtonNews = (props)=>{
    const [ hover, setHover ] = useState(false); 
    const clientParameters = useSelector(parameters); 

    const styles = StyleSheet.create({
        default : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12
        },
        hovered : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12,
            backgroundColor : "#f5f6fa"
        }
    })


    return(
        <Pressable
            style={ props.style }
            onPress={ props.onClick }

        >
            <View style={{
                  flexDirection : "row", 
                  alignItems : "center", 
                  paddingLeft : 24, 
                  paddingRight : 8,
                  paddingTop : 12, 
                  paddingBottom : 12,
                  backgroundColor : (props.hovered && !props.active ? "#f5f6fa" : (props.active ? clientParameters.warna_header : null))
                }}
                onMouseEnter={ props.onMouseEnter }
                onMouseLeave={ props.onMouseLeave }
            >
                <IconNews 
                    width={24}
                    height={24}
                    color={ (props.active ? "white" : props.color )}
                />
                <Label 
                    size={14}
                    weight={"600"}
                    text={"Berita"}
                    color={(props.active ? "white" : props.color )}
                    style={{marginLeft : 12 }}
                />
            </View>
        </Pressable>
    )
}

const ButtonRack = (props)=>{
    const [ hover, setHover ] = useState(false); 
    const clientParameters = useSelector(parameters); 

    const styles = StyleSheet.create({
        default : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12
        },
        hovered : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12,
            backgroundColor : "#f5f6fa"
        }
    })


    return(
        <Pressable
            style={ props.style }
            onPress={ props.onClick }

        >
            <View style={{
                  flexDirection : "row", 
                  alignItems : "center", 
                  paddingLeft : 24, 
                  paddingRight : 8,
                  paddingTop : 12, 
                  paddingBottom : 12,
                  backgroundColor : (props.hovered && !props.active ? "#f5f6fa" : (props.active ? clientParameters.warna_header : null))
                }}
                onMouseEnter={ props.onMouseEnter }
                onMouseLeave={ props.onMouseLeave }
            >
                <IconFolder 
                    width={24}
                    height={24}
                    color={ (props.active ? "white" : props.color )}
                />
                <Label 
                    size={14}
                    weight={"600"}
                    text={"Rak Pinjam"}
                    color={(props.active ? "white" : props.color )}
                    style={{marginLeft : 12 }}
                />
            </View>
        </Pressable>
    )
}

const ButtonBag = (props)=>{
    const [ hover, setHover ] = useState(false); 
    const clientParameters = useSelector(parameters); 

    const styles = StyleSheet.create({
        default : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12
        },
        hovered : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12,
            backgroundColor : "#f5f6fa"
        }
    })


    return(
        <Pressable
            style={ props.style }
            onPress={ props.onClick }

        >
            <View style={{
                  flexDirection : "row", 
                  alignItems : "center", 
                  paddingLeft : 24, 
                  paddingRight : 8,
                  paddingTop : 12, 
                  paddingBottom : 12,
                  backgroundColor : (props.hovered && !props.active ? "#f5f6fa" : (props.active ? clientParameters.warna_header : null))
                }}
                onMouseEnter={ props.onMouseEnter }
                onMouseLeave={ props.onMouseLeave }
            >
                <IconBag 
                    width={24}
                    height={24}
                    color={ (props.active ? "white" : props.color )}
                />
                <Label 
                    size={14}
                    weight={"600"}
                    text={"Donasi"}
                    color={(props.active ? "white" : props.color )}
                    style={{marginLeft : 12 }}
                />
            </View>
        </Pressable>
    )
}

const ButtonCart = (props)=>{
    const [ hover, setHover ] = useState(false); 
    const clientParameters = useSelector(parameters); 

    const styles = StyleSheet.create({
        default : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12
        },
        hovered : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12,
            backgroundColor : "#f5f6fa"
        }
    })


    return(
        <Pressable
            style={ props.style }
            onPress={ props.onClick }

        >
            <View style={{
                  flexDirection : "row", 
                  alignItems : "center", 
                  paddingLeft : 24, 
                  paddingRight : 8,
                  paddingTop : 12, 
                  paddingBottom : 12,
                  backgroundColor : (props.hovered && !props.active ? "#f5f6fa" : (props.active ? clientParameters.warna_header : null))
                }}
                onMouseEnter={ props.onMouseEnter }
                onMouseLeave={ props.onMouseLeave }
            >
                <Badge badgeContent={ props.count } color={"warning"}>
                    <IconCart 
                        width={24}
                        height={24}
                        color={ (props.active ? "white" : props.color )}
                    />
                </Badge>
                
                <Label 
                    size={14}
                    weight={"600"}
                    text={"Keranjang"}
                    color={(props.active ? "white" : props.color )}
                    style={{marginLeft : 12}}
                />


                
            </View>
        </Pressable>
    )
}

const ButtonApp = (props)=>{
    const [ hover, setHover ] = useState(false); 
    const clientParameters = useSelector(parameters); 

    const styles = StyleSheet.create({
        default : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12
        },
        hovered : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12,
            backgroundColor : "#f5f6fa"
        }
    })


    return(
        <Pressable
            style={ props.style }
            onPress={ props.onClick }

        >
            <View style={{
                  flexDirection : "row", 
                  alignItems : "center", 
                  paddingLeft : 24, 
                  paddingRight : 8,
                  paddingTop : 12, 
                  paddingBottom : 12,
                  backgroundColor : (props.hovered && !props.active ? "#f5f6fa" : (props.active ? clientParameters.warna_header : null))
                }}
                onMouseEnter={ props.onMouseEnter }
                onMouseLeave={ props.onMouseLeave }
            >
                <IconAppIntegrate 
                    width={24}
                    height={24}
                    color={ (props.active ? "white" : props.color )}
                />
                <Label 
                    size={14}
                    weight={"600"}
                    text={"Integrasi"}
                    color={(props.active ? "white" : props.color )}
                    style={{marginLeft : 12 }}
                />
            </View>
        </Pressable>
    )
}

const ButtonTransaction = (props)=>{
    const [ hover, setHover ] = useState(false); 
    const clientParameters = useSelector(parameters); 

    const styles = StyleSheet.create({
        default : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12
        },
        hovered : {
            flexDirection : "row", 
            alignItems : "center", 
            paddingLeft : 24, 
            paddingRight : 8,
            paddingTop : 12, 
            paddingBottom : 12,
            backgroundColor : "#f5f6fa"
        }
    })


    return(
        <Pressable
            style={ props.style }
            onPress={ props.onClick }

        >
            <View style={{
                  flexDirection : "row", 
                  alignItems : "center", 
                  paddingLeft : 24, 
                  paddingRight : 8,
                  paddingTop : 12, 
                  paddingBottom : 12,
                  backgroundColor : (props.hovered && !props.active ? "#f5f6fa" : (props.active ? clientParameters.warna_header : null))
                }}
                onMouseEnter={ props.onMouseEnter }
                onMouseLeave={ props.onMouseLeave }
            >
                <Badge badgeContent={ props.count } color={"warning"}>
                    <IconTransaction 
                        width={24}
                        height={24}
                        color={ (props.active ? "white" : props.color )}
                    />
                </Badge>
                <Label 
                    size={14}
                    weight={"600"}
                    text={"Transaksi"}
                    color={(props.active ? "white" : props.color )}
                    style={{marginLeft : 12}}
                />
            </View>
        </Pressable>
    )
}


export { ButtonHome, ButtonNews, ButtonRack, ButtonBag, ButtonCart, ButtonApp, ButtonTransaction }