import React, { useState, useEffect  } from 'react'; 
import { View, StyleSheet, Pressable, FlatList, ScrollView } from 'react-native'; 
import { Label } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { useSelector, useDispatch } from 'react-redux'
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog } from '../components/DialogComponents';
import { parameters } from '../reducers/parameterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { Tab , Tabs, CardActionArea } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ContentDetail } from './ContentDetail';

var _bookData = [];
const Categories = (props)=>{
    const isMounted = useIsMounted()
    const clientParams = useSelector(parameters); 
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });
    
    const [progressState, setProgressState ] = useState({
        showProgress : false, 
        showError : false, 
        showSuccess : false, 
        message : ""
    })

    const [selectedCategory, setSelectedCategory] = useState({
        activeDept : props.data[0].id_departemen, 
        activeId : props.activeId, 
        activeTitle : props.activeTitle,
        page : 1, 
        show : 0, 
        total : 0
    })

    const [dataCategory , setDataCategory] = useState([])
    const [bookData, setBookData]= useState([]); 
    const [tabValue, setTabValue ] = useState(0);
    const [renderedBookData, setRenderedBookData ] = useState([]); 

    useEffect(()=>{
        if(selectedCategory.activeId == "") {
            let data_kategori = props.data[0].data_kategori; 
            let selected_category = {
                activeId : data_kategori[0].id_kategori, 
                activeTitle : data_kategori[0].nama_kategori ,
                page : 1, 
                show : 0, 
                total : 0
            }
            if(isMounted()){
                setSelectedCategory(selected_category);
                setDataCategory(data_kategori);
            }
          
        } else {
            let data_kategori = props.data[0].data_kategori; 
            if(isMounted()){
                setDataCategory(data_kategori);
                fetchData();
            }
            
        }
    },[selectedCategory.activeId ])


    useEffect(()=>{
        let book_data = [...bookData]; 
        let mod = book_data.length % 7;

        if(mod > 0 ) {
            let add_factor = 7 - mod; 
            for(let i = 0;i<add_factor;i++){
                book_data.push({ dummy : i})
            }
        }

        setRenderedBookData(book_data)

    },[bookData.length])

    useEffect(()=>{
        if(selectedCategory.page != 1){
            
            fetchData();
        }
    },[ selectedCategory.page ])

    const fetchData = async()=>{
        let progress_state = { ...progressState } 
        progress_state = {
            showProgress : true, 
            message : "Memuat data", 
            showError : false,
            showSuccess : false
        }

        setProgressState(progress_state);
        let formData = new FormData();
        formData.append("page", selectedCategory.page );
        formData.append("id_kategori", selectedCategory.activeId); 
        formData.append("limit", "100"); 
        formData.append("id_dept", selectedCategory.activeDept);
        
        const response = await executePost(api.categoryDetail, formData); 
        //console.log(JSON.stringify(response)); 
        if(response.code == resCode.ok){
            progress_state.showProgress = false; 
            setProgressState(progress_state); 
            let total = response.count; 
            let show = selectedCategory.show + response.total; 
            let selected_category = { ...selectedCategory} 
            selected_category.total = total; 
            selected_category.show = show;  
            let book_data = [...bookData, ...response.data]; 
            setBookData(book_data);  
            setSelectedCategory(selected_category);

        } else {
            progress_state.showProgress = false;
            if(response.code != resCode.error) {
                progress_state.showError = false; 
                setProgressState(progress_state);
            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");
            }
        }
    }

    const fetchMoreData = ()=>{
        let selected_category = { ...selectedCategory} 
        let total = selected_category.total; 
        let show = selected_category.show; 
        if(show < total){
            console.log("SHOW < TOTAL");
            selected_category.page += 1; 
            setSelectedCategory(selected_category);
        } else {
            console.log("SHOW == TOTAL");
        }

    }

    const handleTabChange = (event, newValue)=>{
        if(isMounted()){
            setTabValue(newValue);
            let data_kategori = props.data[newValue].data_kategori;
            if(data_kategori.length > 0 ) {
                let selected_category = {
                    activeDept : props.data[newValue].id_departemen, 
                    activeId : data_kategori[0].id_kategori, 
                    activeTitle : data_kategori[0].nama_kategori,
                    page : 1, 
                    show : 0, 
                    total : 0
                }
                setBookData([]);
                setSelectedCategory(selected_category);
            }
            setDataCategory(data_kategori);
        }
    }

    const onCategoryClick = (item)=>{
        if(isMounted()){
            let id_kategori = item.id_kategori; 
            let nama_kategori = item.nama_kategori; 
            let id_dept = selectedCategory.activeDept;
            let selected_category = {
                activeDept : id_dept, 
                activeId : id_kategori, 
                activeTitle : nama_kategori,
                page : 1, 
                total : 0, 
                show : 0
            }
            setBookData([]);
            setSelectedCategory(selected_category);
        }

    }

    const renderCategory = (item, index)=>{
        return(
            <CardActionArea
                key={ "category_"+index}
                    style={{
                        borderRadius : 24,
                        cursor : "pointer",
                        borderColor :  clientParams.warna_header,
                        height : 42, 
                        marginLeft : (index == 0 ? 16 : 0 ),
                        marginRight : (index == dataCategory.length - 1 ? 8 : 16)
                    }}
                    onClick={()=>{ onCategoryClick(item)}}
                >
                    <View
                        style={{
                            borderRadius : 24, 
                            borderColor :  (selectedCategory.activeId == item.id_kategori ? null : clientParams.warna_header),
                            borderWidth : (selectedCategory.activeId == item.id_kategori ? 0 : 1 ),
                            alignItems : "center", 
                            justifyContent : "center",
                            height : 42,
                            paddingLeft : 12, 
                            paddingRight : 12, 
                            backgroundColor : (selectedCategory.activeId == item.id_kategori ? clientParams.warna_header : null )
                        }}
                    >
                            
                        <Label
                            size={12}
                            weight={"600"}
                            color={ (selectedCategory.activeId == item.id_kategori ? "white" : clientParams.warna_header) }
                            text={item.nama_kategori}
                            textAlign={"center"}
                            style={{
                                marginLeft : 8, 
                                marginRight : 8, 
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                    </View>
            </CardActionArea>
        )
    }
    //ANCHOR - Render Item
    const renderItem = ({item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
            return(
                <Pressable
                    key={"book_"+index}
                    style={{ 
                        borderRadius : 12,
                        borderWidth : 0, 
                        marginRight : (index != props.data.length - 1 ? 12 : 0 ),
                        marginBottom : 12,
                        cursor : "pointer",
                    }}
                    onPress={()=>{ onContentClick(item)}}
                >
                    <View 
                        style={{
                            alignItems : "center",
                            height : 300
                        }}
                    >
                        <img 
                            src={ item.img_file}
                            style={{
                                marginTop : 8, 
                                width : 120, 
                                borderRadius : 12
                            }}
                        />
                        <Label 
                            size={12}
                            text={item.judul}
                            weight={"600"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                paddingLeft : 8, 
                                paddingRight : 8, 
                                marginTop : 4, 
                                marginBottom : 4, 
                                maxWidth : 120,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label 
                            size={10}
                            text={item.penulis}
                            weight={"600"}
                            color={"#2980b9"}
                            align={"left"}
                            textAlign={"left"}
                            style={{
                                minWidth : 120, 
                                paddingLeft : 8, 
                                paddingRight : 8,  
                                marginTop : 4, 
                                marginBottom : 12, 
                                maxWidth : 120,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis",
                            
                            }}
                        />
                        <View 
                            style={{
                                padding : 4, 
                                borderRadius : 8, 
                                borderWidth : 0.3,
                                borderColor : "#130f40", 
                                alignItems : "center", 
                                justifyContent : "center",
                                position : "absolute", 
                                bottom : 0
                        }}>
                            <Label 
                                size={10}
                                weight={"600"}
                                color={"#130f40"}
                                text={"Stok: "+item.stok + "/" + item.qty}
                                textAlign={"center"}
                            />
                        </View>

                    </View>
                </Pressable>
            )
        } else {
            return(
                <View style={{ flex : 1}}>
                </View>
            )
        }
    }


    const [details, setDetails ] = useState({
        show : false,
        id : "", 
        title : ""
    }); 
    

    const onContentClick = (item)=>{
        props.navigation.navigate("DetailPage", item); 
        // let id_konten = item.id_konten; 
        // let judul = item.judul ; 
        // setDetails({
        //     show : true, 
        //     id : id_konten, 
        //     title : judul
        // })
        
    }

    
    //ANCHOR - Render Return 
    return(
        <ThemeProvider theme={ theme }>
            <View style={{flex : 1 }}>
                <View style={{ flexDirection : "row", alignItems : "center", marginLeft : 16 , marginTop : 16 }}>
                    <Pressable
                        onPress={ props.onBackClick }
                    >
                        <ArrowBackIcon sx={{ color : clientParams.warna_header }}/> 
                    </Pressable>
                    <Label 
                        size={14}
                        weight={"400"}
                        style={{marginLeft : 8, cursor : "pointer" }}
                        color={ clientParams.warna_header}
                        text={"Kembali"}
                        onClick={ props.onBackClick }
                    />
                </View>
                {
                    (props.data.length > 0) && 
                    <View style={{flex : 1}}>
                        <Tabs value={tabValue} style={{marginLeft : 16, marginRight : 16 }} onChange={handleTabChange }>
                            {
                                props.data.map((item, index)=>(
                                    <Tab label={ item.nama_departemen} key={item.id_departemen}/>
                                ))
                            }
                        </Tabs>
                        {/* <FlatList 
                            keyExtractor={(item, index)=>"category_id_"+item.id_kategori}
                            contentContainerStyle={{maxHeight : 42, marginTop : 8, marginBottom : 64  }}
                            showsHorizontalScrollIndicator={false}
                            horizontal={true}
                            data={dataCategory}
                            renderItem={renderCategory}
                        /> */}
                       
                        
                        <FlatList
                            ListHeaderComponent={
                                <ScrollView 
                                    horizontal={true}
                                    showsHorizontalScrollIndicator={false}
                                    contentContainerStyle={{ height : 60 ,paddingTop : 16,  paddingBottom : 16 , backgroundColor : "white" }}
                                    >
                                    {
                                        dataCategory.map((item , index)=>(
                                            renderCategory(item, index)
                                        ))
                                    }
                                </ScrollView>
                            }
                            stickyHeaderIndices={[0]}
                            keyExtractor={(i,x)=>"book_id_"+x}
                            numColumns={7}
                            data={renderedBookData}
                            renderItem={renderItem}
                            showsVerticalScrollIndicator={false}
                            contentContainerStyle={{
                                marginLeft : 16 , 
                                marginRight : 16 , 
                            }}
                            onEndReachedThreshold={0.2}
                            onEndReached={ fetchMoreData }
                        />
                    </View>
                }
                

                <ErrorDialog 
                    open={ progressState.showError}
                    message={ progressState.message }
                    label={"OK"}
                    onClick={()=>{
                        let params = { ...progressState};
                        params.showError = false;
                        setProgressState(params);
                    }}
                />
                <ProgressDialog 
                    open={ progressState.showProgress }
                    message={ progressState.message }
                />
                <SuccessDialog
                    open={ progressState.showSuccess }
                    message={progressState.message}
                    label={"OK"}
                    onClick={()=>{
                        let _progressState = { ...progressState} 
                        _progressState.showSuccess = false;
                        setProgressState(_progressState)
                    }}
                />
                {/* <ContentDetail 
                    open={ details.show }
                    start={ details.show }
                    id={ details.id }
                    title={ details.title }
                    onClose={()=>{
                        let _details = {...details}
                        _details.show = false; 
                        setDetails(_details);
                    }}
                    navigation={props.navigation}
                    
                /> */}
            </View>
        </ThemeProvider>
    )
}




export { Categories }