import React, { useState, useEffect} from 'react'; 
import { View, StyleSheet, FlatList,Pressable, Dimensions } from 'react-native'; 
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog,NewsDetailDialog } from '../components/DialogComponents';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams } from '../reducers/parameterSlice';
import { Label, PushButton } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { Paper, Card, CardActionArea, CardHeader,CardMedia, CardContent } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AppIntegration = (props)=>{
    const clientParams = useSelector(parameters); 
    const isMounted = useIsMounted();
    let initProgressState = {
        showProgress : false,
        showError : false, 
        message : "Memuat Data",
        start : true
    }
    let initPageState = {
        page : 1, 
        total : 0, 
        show : 0
    }
    const [progressState,setProgressState] = useState(initProgressState);
    const [pageState, setPageState ] = useState(initPageState); 
    const [data, setData ]= useState([]);
    const [renderedData, setRenderedData ] = useState([]); 


    useEffect(()=>{
        if(progressState.start){
            let progress = { ...progressState }
            progress.showProgress = true; 
            progress.message = "Memuat Data";
            setProgressState(progress); 
            fetchData();
        }
    },[progressState.start])

    useEffect(()=>{
        if(data.length > 0) {
            let _rendered_data = [...data]; 
            let mod = data.length % 2; 
            if(mod > 0 ) {
                let add_factor = 2 - mod; 
                for(let i=0;i<add_factor;i++){
                    _rendered_data.push({ dummy : i}); 
                }
            }
            setRenderedData(_rendered_data); 

        }
        
    },[data.length])


    const fetchData = async()=>{
        let formData = new FormData(); 
        formData.append("page", pageState.page); 

        const response = await executePost(api.integratedAppList, formData); 
        let progress = { ...progressState }
        progress.start = false;
        if(response.code == resCode.ok){
            console.log(JSON.stringify(response));
            progress.showProgress = false;
            setProgressState(progress); 
            if(response.data.length > 0 ) {
                setData(response.data);
            }

        } else {
            if(response.code != resCode.error){
                progress.showError = true; 
                progress.message = response.msg; 
                setProgressState(progress); 
            } else {
                //ANCHOR - Force Logout 
                window.postMessage({ command : "FORCE_LOGOUT"}, "*"); 
            }
        }

    }

    const renderItem = ({ item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
            return(
                <View 
                    key={"app_int_"+index}
                    style={{
                        flexDirection : "row", 
                        borderRadius : 12,
                        borderWidth : 0, 
                        height : 150,
                        flex : 1, 
                        marginTop : 4, 
                        marginBottom : 4, 
                        marginRight : (index % 2 == 0 ? 16 :  0),  
                        cursor : "pointer"
                    }}
                >
                    <img 
                        src={ item.url_icon}
                        style={{
                            width : 140, 
                            borderRadius : 12
                        }}
                    />
                    <View style={{marginLeft : 16, flex : 1}}>
                        <Label 
                            size={14}
                            text={item.nama}
                            weight={"400"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                marginTop : 16, 
                                maxWidth : 350,
                                marginBottom : 4, 
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 3, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label 
                            size={12}
                            text={item.deskripsi}
                            weight={"400"}
                            color={"#2980b9"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                marginTop : 0, 
                                maxWidth : 350,
                                marginBottom : 0, 
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 3, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />


                        <View style={{ flexDirection : "row-reverse", alignItems : "center", position : "absolute", bottom : 0, right : 0}}>
                            <PushButton 
                                text={"Buka"} 
                                variant={"contained"} 
                                textSize={12}
                                textColor={"white"}
                                onClick={()=>{
                                    try {
                                        let uri = new URL(item.android_uri); 
                                        let url = uri.searchParams.get("url"); 
                                        window.electron.sendMessage("OPEN_URL", url);
                                    } catch(error){

                                    }
                                }}
                                
                            />
                        </View>
                    </View>
                </View>
            )
        } else {
            return(
                <View style={{flex : 1}}>
                </View>
            )
        }
    }

    return(
        <View style={{ flex : 1 }}>

            <View style={{ flexDirection : "row", alignItems : "center", marginLeft : 16 , marginTop : 16, marginBottom : 16 }}>
                <Pressable
                    onPress={ props.onBackClick }
                >
                    <ArrowBackIcon sx={{ color : clientParams.warna_header }}/> 
                </Pressable>
                <Label 
                    size={14}
                    weight={"600"}
                    style={{marginLeft : 8, cursor : "pointer" }}
                    color={ clientParams.warna_header}
                    text={ "Aplikasi Terintegrasi" }
                />
            </View>
            {
                (!progressState.showProgress && data.length > 0 ) && 
                <FlatList 
                    keyExtractor={(i, x)=>"app_idx_"+x} 
                    data={renderedData}
                    renderItem={renderItem}
                    numColumns={ 2 }
                    contentContainerStyle={{
                        marginLeft : 16, 
                        marginRight : 16
                    }}
                    showsVerticalScrollIndicator={ false }
                />
            }
            
            
            <ProgressDialog 
                open={ progressState.showProgress}
                message={ progressState.message }
            />
            <ErrorDialog
                open={ progressState.showError}
                message={ progressState.message}
                label={"OK"}
                onClick={()=>{
                    let progress_state = { ...progressState }
                    progress_state.showError = false;
                    setProgressState(progress_state); 
                }}
            />

        </View>
    )

}

export { AppIntegration }



