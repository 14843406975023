import React, { useState, useEffect,useImperativeHandle, useRef  } from 'react'; 
import { View, StyleSheet, Pressable, FlatList, ScrollView } from 'react-native'; 
import { Label, PushButton  } from '../components/FormComponents';
import { useIsMounted } from '../utilities/System';
import { useSelector, useDispatch } from 'react-redux'
import { Progress, ProgressDialog, ErrorDialog, SuccessDialog } from '../components/DialogComponents';
import { parameters } from '../reducers/parameterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import { Tab , Tabs, CardActionArea, Radio, RadioGroup, FormControlLabel, FormGroup, FormControl } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { ContentDetail } from './ContentDetail';

const SearchPage = React.forwardRef((props, innerRef)=>{
    const clientParams = useSelector(parameters); 
    const isMounted = useIsMounted(); 
    var _is_mounted = false;
    let initProgressState = {
        showProgress : false, 
        showError : false, 
        message : "",
        start : false
    }

    let initPageState = {
        page : -1, 
        total : 0,
        show : 0, 
    }
    const selectedItemRef = useRef(); 
    const [searchKeyword, setSearchKeyword] = useState(props.keyword); 
    const [progressState, setProgressState] = useState(initProgressState);
    const [pageState, setPageState] = useState(initPageState); 
    const [data, setData ] = useState([]); 
    const [renderedData, setRenderedData ] = useState([]);
    const [bookData, setBookData]= useState({id : "-"})
    


    useImperativeHandle(innerRef, ()=>({
        startSearch
    }))

    useEffect(()=>{
        _is_mounted = true;
        if(progressState.start){
            fetchData(); 
        }

        return ()=>{
            _is_mounted = false;
        }

    },[progressState.start])

    useEffect(()=>{
        if(bookData.id != "-"){
            readRequest();
        }
    },[bookData])

    

    const startSearch = ()=>{
        
        let page_state = { 
            page : 1, 
            total : 0, 
            show : 0
        }
        let progress_state = {
            showProgress : true, 
            showError : false, 
            message : "Melakukan pencarian",
            start : true
        }
        setPageState(page_state); 
        setProgressState(progress_state); 
        setData([]); 
        setRenderedData([])

    }


    const fetchData = async()=>{
        //console.log("FETCH DATA");
        let formData = new FormData(); 
        formData.append("page", pageState.page);
        formData.append("keyword", props.keyword); 
        formData.append("limit", 100); 
        let url = "";
        if(props.type == "umum"){
            url = api.searching; 
        } else {
            url = api.searchingKalimat; 
        }
       
        const response = await executePost(url, formData); 
        let progress = { ...progressState};
        progress.start = false;

        //console.log(JSON.stringify(response));
        if(response.code == resCode.ok){
            if(_is_mounted){
                progress.showProgress = false; 
                setProgressState(progress); 

                let total = response.total_rows; 
                let show = response.total_show; 
                let page_state = { ...pageState } 
                page_state.total = total; 
                page_state.show += show

                if(show > 0 ) {

                    let _data = [...data, ...response.data] ;
                    let _rendered_data = [...data, ...response.data]; 
                    let mod_factor = (props.type == "umum" ? 7 : 2 ); 
                    let mod = _rendered_data.length % mod_factor; 
                    if(mod > 0 ) {
                        let add_factor = mod_factor - mod; 
                        for(let i = 0 ; i < add_factor;i++){
                            _rendered_data.push({ dummy : i }); 
                        }
                    }
                    setData(_data); 
                    setRenderedData(_rendered_data);
                }

                setPageState(page_state);
            }

        
        } else {
            if(response.code != resCode.error){

                progress.showError = true; 
                progress.showProgress = false;
                progress.message = response.msg;
                setProgressState(progress); 

            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");

            }
        }
    
    }       

    const renderItem = ({item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
            return(
                <Pressable
                    key={"book_"+index}
                    style={{ 
                        borderRadius : 12,
                        borderWidth : 0, 
                        marginRight : (index != renderedData.length - 1 ? 12 : 0 ),
                        marginBottom : 12,
                        cursor : "pointer",
                    }}
                    onPress={()=>{ onContentClick(item)}}
                >
                    <View 
                        style={{
                            alignItems : "center",
                            height : 300
                        }}
                    >
                        <img 
                            src={ item.cover_file}
                            style={{
                                marginTop : 8, 
                                width : 120, 
                                borderRadius : 12
                            }}
                        />
                        <Label 
                            size={12}
                            text={item.judul}
                            weight={"600"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                paddingLeft : 8, 
                                paddingRight : 8, 
                                marginTop : 4, 
                                marginBottom : 4, 
                                maxWidth : 120,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label 
                            size={10}
                            text={item.penulis}
                            weight={"600"}
                            color={"#2980b9"}
                            align={"left"}
                            textAlign={"left"}
                            style={{
                                minWidth : 120, 
                                paddingLeft : 8, 
                                paddingRight : 8,  
                                marginTop : 4, 
                                marginBottom : 12, 
                                maxWidth : 120,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis",
                            
                            }}
                        />
                        <View 
                            style={{
                                padding : 4, 
                                borderRadius : 8, 
                                borderWidth : 0.3,
                                borderColor : "#130f40", 
                                alignItems : "center", 
                                justifyContent : "center",
                                position : "absolute", 
                                bottom : 0
                        }}>
                            <Label 
                                size={10}
                                weight={"600"}
                                color={"#130f40"}
                                text={"Stok: "+item.stok + "/" + item.qty}
                                textAlign={"center"}
                            />
                        </View>

                    </View>
                </Pressable>
            )
        } else {
            return(
                <View style={{ flex : 1}}>
                </View>
            )
        }
    }
    const renderContent = ({item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")){
            return(
                <View 
                key={"book_"+index}
                style={{
                    flexDirection : "row", 
                    borderRadius : 12,
                    borderWidth : 0, 
                    height : 200,
                    flex : 1, 
                    marginRight : (index != renderedData.length - 1 ? 12 : 0 ),
                    marginBottom : 12,
                    cursor : "pointer"
                }}>
                    <img 
                        src={ item.cover}
                        style={{
                            width : 140, 
                            borderRadius : 12
                        }}
                    />
                    <View style={{marginLeft : 12, flex : 1 }}>
                        <Label 
                            size={14}
                            text={item.judul}
                            weight={"600"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                marginTop : 4, 
                                maxWidth : 350,
                                marginBottom : 4, 
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 3, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label 
                            size={12}
                            text={"Halaman: "+item.halaman}
                            weight={"600"}
                            color={"#2980b9"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                marginTop : 0, 
                                maxWidth : 350,
                                marginBottom : 0, 
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 3, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Label 
                            size={14}
                            text={"Stok: "+item.stok + "/" + item.qty }
                            weight={"600"}
                            color={"#2980b9"}
                            align={"left"}
                            style={{
                                minWidth : 120, 
                                marginTop : 2   , 
                                maxWidth : 350,
                                marginBottom : 4, 
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 3, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        />
                        <Typography
                            fontFamily={"Poppins"}
                            fontSize={12}
                            fontWeight={"400"}
                            component={"span"}
                            style={{
                                marginTop : 12,   
                                maxWidth : 350,
                                display : "-webkit-box", 
                                WebkitBoxOrient: "vertical", 
                                WebkitLineClamp : 2, 
                                overflow : "hidden", 
                                textOverflow : "ellipsis"
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{ __html: item.hal_txt}}
                            />
                        </Typography>

                        <View style={{ flexDirection : "row-reverse", alignItems : "center", position : "absolute", bottom : 0, right : 0}}>
                            <PushButton 
                                text={"Baca"} 
                                variant={"contained"} 
                                textSize={12}
                                textColor={"white"}
                                onClick={()=>{ onReadClick(item)}}
                            />
                            <PushButton 
                                text={"Detail"} 
                                variant={"outlined"} 
                                textSize={12}
                                style={{marginRight : 12 }}
                                onClick={()=>{
                                    let params = {
                                        id_konten : item.id, 
                                        judul : item.judul
                                    }
                                    onContentClick(item);
                                }}
                            />

                        </View>
                    </View>
                </View>
            )
        } else {
            return(
                <View style={{flex: 1}}>

                </View>
            )
        }
    }

    const fetchMoreData = ()=>{
        let page_state = { ...pageState }
        console.log(JSON.stringify(page_state));
        if(page_state.show < page_state.total ) {
            page_state.page += 1; 
            let progress_state = { ...progressState }
            progress_state.showProgress = true; 
            progress_state.message = "Memuat data"; 
            progress_state.start = true; 
            setProgressState(progress_state); 
        }
    }

    
    //ANCHOR - DETAIL PAGE
    const onContentClick = (item)=>{
        
        if(!item.hasOwnProperty("id_konten")){
            item.id_konten = item.id; 
        }
        item.img_file = item.cover_file;
        //console.log(JSON.stringify(item));
        
        props.navigation.navigate("DetailPage", item);    
    }
    //ANCHOR - READ CLICK
    const onReadClick = (item)=>{
        let _progressState = {...progressState}
        _progressState.showProgress = true; 
        _progressState.message = "Checking"; 
        setProgressState(_progressState); 
        selectedItemRef.current = item; 
        fetchDetails(item); 

    }


    //ANCHOR - GET CONTENT DETAIL
    const fetchDetails = async(item)=>{
        let _progressState = {...progressState} 
        let formData = new FormData();
        formData.append("id_konten", item.id); 

        const response = await executePost(api.detailContent, formData); 
        let progress = { ...progressState}
        _progressState.showProgress = false;
        if(response.code == resCode.ok){
            //console.log(JSON.stringify(response.data)); 
            setBookData(response.data); 
            //readCheck(id);
        } else {
            if( response.code != resCode.error){
                
                _progressState.showError = true; 
                _progressState.message = response.msg; 
                setProgressState(_progressState); 

            } else {
                //ANCHOR - Force Logout
                window.postMessage({command : "FORCE_LOGOUT"}, "*");

            }
        }

    }


    //ANCHOR - READ REQUEST
    const readRequest = async()=>{
        let _progressState = {...progressState} 
        let formData = new FormData(); 
        formData.append("id_konten", selectedItemRef.current.id);

        const response = await executePost(api.requestBaca, formData); 
        _progressState.showProgress = false;
        if(response.code == resCode.ok){
            //console.log(JSON.stringify(selectedItemRef.current));
            setProgressState(_progressState); 
            let searchData = { ...selectedItemRef.current } 
            searchData.keyword = props.keyword; 
            let data = {
                book : bookData, 
                read : response, 
                readMode : true, 
                search : searchData
            }

            props.navigation.navigate("Reader", data); 
        } else {
            if(response.code != resCode.error){
                _progressState.showError = true; 
                _progressState.message = response.msg;
                setProgressState(_progressState)
            } else {
                //ANCHOR - Force Logout 
                window.postMessage({command : "FORCE_LOGOUT"}, "*");

            }
        }

    }

    return( 
        <View style={{flex : 1}}>
            <View style={{ flexDirection : "row", alignItems : "center", marginLeft : 16 , marginTop : 16, marginBottom : 16 }}>
                <Pressable
                    onPress={ props.onBackClick }
                >
                    <ArrowBackIcon sx={{ color : clientParams.warna_header }}/> 
                </Pressable>
                <Label 
                    size={14}
                    weight={"400"}
                    style={{marginLeft : 8, cursor : "pointer" }}
                    color={ clientParams.warna_header}
                    text={"Hasil Pencarian : " }
                />
                <Label 
                    size={14}
                    weight={"600"}
                    style={{marginLeft : 8, cursor : "pointer" }}
                    color={ clientParams.warna_header}
                    text={ props.keyword }
                />
                <Label
                    size={12}
                    weight={"400"}
                    style={{marginLeft : 8 }} 
                    color={ clientParams.warna_header }
                    text={ "(ditemukan " + pageState.total +" data)"}
                />
            </View>
            {
                (data.length > 0 && props.type == "umum") && 
                <FlatList 
                    keyExtractor={(item , index)=>"search_index_"+index} 
                    data={ renderedData }
                    renderItem={renderItem }
                    numColumns={ 7 }
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        marginTop : 16,
                        marginLeft : 16, 
                        marginRight : 16 
                    }}
                    onEndReachedThreshold={ 0.5 }
                    onEndReached={ fetchMoreData }

                />
            }
            {
                (data.length > 0 && props.type == "kalimat") && 
                <FlatList 
                    keyExtractor={(item , index)=>"search_index_"+index} 
                    data={ renderedData }
                    renderItem={ renderContent }
                    numColumns={ 2 }
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={{
                        marginTop : 16,
                        marginLeft : 16, 
                        marginRight : 16 
                    }}
                    onEndReachedThreshold={ 0.5 }
                    onEndReached={ fetchMoreData }

                />
            }
            <ProgressDialog 
                open={ progressState.showProgress }
                message={ progressState.message } 
            />
            <ErrorDialog 
                open={ progressState.showError }
                message={ progressState.message }
                label={"OK"}
                onClick={()=>{
                    let progress = { ...progressState }
                    progress.showError = false; 
                    setProgressState(progress); 
                }}
            />
            


        </View>
    )
})


export { SearchPage }