import React, { useEffect, useState, useRef } from 'react'; 
import { View, StyleSheet } from 'react-native'; 
import { Label, TextField } from '../components/FormComponents';
import { useSelector, useDispatch } from 'react-redux'
import { setClientConfiguration, parameters, userParams, setUserParams } from '../reducers/parameterSlice';
import { Paper, Avatar, RadioGroup, FormControl, Radio, FormControlLabel, 
    Popover, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'; 
import { executeGet, executePost, api, resCode } from '../utilities/Network';
import SearchIcon from '@mui/icons-material/Search';
import { IconHome, IconNews, IconFolder, IconBag, IconUser } from '../assets/Icons';
import { ButtonHome, ButtonNews, ButtonRack, ButtonBag, ButtonCart, ButtonApp, ButtonTransaction } from '../components/SideMenuButtons';
import { Home } from './Home';
import { News } from './News';
import { BookRack } from './BookRack';
import { DonationStore } from './DonationStore';
import { DonationCart } from './DonationCart';
import { TransactionList } from './TransactionList';
import { useIsMounted, createHeaders } from '../utilities/System';
import { SearchPage } from './SearchPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ConfirmDialog, ProgressDialog, BookRequestDialog, SuccessDialog } from '../components/DialogComponents';
import  AsyncStorage  from '@react-native-async-storage/async-storage';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import * as DocumentPicker from 'expo-document-picker'; 
import { dataURLToFile } from '../utilities/System';

//== Main Desktop ==// 
const Main = (props)=>{
    const clientParams = useSelector(parameters);
    const dispatch = useDispatch();
    //console.log("CLIENT PARAMS"); 
    //console.log(JSON.stringify(clientParams)); 
    const userInfo = useSelector(userParams);  
    const isMounted = useIsMounted();
    var _is_mounted = false;
    const theme = createTheme({
        palette: {
            primary: {
                main: clientParams.warna_header ,
            },
        },
    });

    //ANCHOR - State Initiation
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchType, setSearchType] = useState("umum");
    const [notificationAvailable, setNotificationAvailable] = useState(false);
    const [homeHovered , setHomeHovered] = useState(false); 
    const [newsHovered, setNewsHovered] = useState(false);
    const [folderHovered, setFolderHovered ] = useState(false);
    const [bagHovered, setBagHovered] = useState(false); 
    const [cartHovered, setCartHovered] = useState(false); 
    const [trxHovered, setTrxHovered] = useState(false); 
    const [firstRun, setFirstRun] = useState(true); 
    const [anchorEl, setAnchorEl] = useState(null);
    const openPopover = Boolean(anchorEl); 
    const [openBookRequestDialog, setOpenBookRequestDialog] = useState(false); 
    const [showSuccessDialog, setShowSuccessDialog] = useState({ show : false, message : ""})

    const [homeData, setHomedata] = useState({ loaded : false}); 
    const [newsData, setNewsData] = useState({ loaded : false });
    const [rackdata, setRackData] = useState({ loaded : false }); 

    const [cartCount, setCartCount] = useState(0); 
    const [pendingTrx , setPendingTrx] = useState(0); 

    const inputKeyword = useRef(null);
    const searchRef = useRef();
    const isUploadingAvatarRef = useRef(false); 
    let initActiveState = {
        home : true, 
        news : false, 
        folder : false, 
        bag : false,
        search : false,
        cart : false, 
        trx : false
    }
    const [activeState, setActiveState ] = useState(initActiveState);
    const [showConfirmLogout, setShowConfirmLogout] = useState(false);
    const [showProgressDialog, setShowProgressDialog] = useState(false);

    const optLogout = ()=>{
        setShowConfirmLogout(true); 
    }
    const onHomeEnter = ()=>{
        setHomeHovered(true);
    }
    const onHomeLeave = ()=>{
        setHomeHovered(false);
    }
    const onNewsEnter = ()=>{
        setNewsHovered(true);
    }
    const onNewsLeave = ()=>{
        setNewsHovered(false);
    }
    const onFolderEnter = ()=>{
        setFolderHovered(true);
    }
    const onFolderLeave = ()=>{
        setFolderHovered(false);
    }
    const onBagEnter = ()=>{
        setBagHovered(true);
    }
    const onBagLeave = ()=>{
        setBagHovered(false);
    }
    const onCartEnter = ()=>{
        setCartHovered(true);
    }
    const onCartLeave = ()=>{
        setCartHovered(false);
    }
    const onTrxEnter = ()=>{
        //console.log("On Mouse Enter");
        setTrxHovered(true);
    }
    const onTrxLeave = ()=>{
        //console.log("On Mouse Leave");
        setTrxHovered(false);
    }
    const homeDataCallback = (params)=>{
        if(isMounted()){
            setHomedata(params);
        }
    }

    const onMenuClick = (which)=>{
        if(which == "home"){
            let menuState = { ...activeState}
            menuState.home = true;
            menuState.news = false; 
            menuState.folder = false;
            menuState.bag = false;
            menuState.search = false;
            menuState.cart = false;
            menuState.trx = false;
            setActiveState(menuState); 
        } else if(which == "news") {
            let menuState = { ...activeState}
            menuState.home = false;
            menuState.news = true; 
            menuState.folder = false;
            menuState.bag = false;
            menuState.search = false;
            menuState.cart = false;
            menuState.trx = false;
            setActiveState(menuState);

        } else if(which == "folder") {
            let menuState = { ...activeState}
            menuState.home = false;
            menuState.news = false; 
            menuState.folder = true;
            menuState.bag = false;
            menuState.search = false;
            menuState.cart = false;
            menuState.trx = false;
            setActiveState(menuState);
        } else if(which == "bag") {
            let menuState = { ...activeState}
            menuState.home = false;
            menuState.news = false; 
            menuState.folder = false;
            menuState.bag = true;
            menuState.search = false;
            menuState.cart = false;
            menuState.trx = false;
            setActiveState(menuState);
        } else if(which == "category") {
            let menuState = { ...activeState } 
            menuState.home = false;
            menuState.news = false; 
            menuState.folder = false;
            menuState.bag = false;
            menuState.search = false;
            menuState.cart = false;
            menuState.trx = false;
            setActiveState(menuState);
        } else if(which == "cart"){
            let menuState = { ...activeState } 
            menuState.home = false;
            menuState.news = false; 
            menuState.folder = false;
            menuState.bag = false;
            menuState.search = false;
            menuState.cart = true;
            menuState.trx = false;
            setActiveState(menuState);
        } else if(which == "trx"){
            let menuState = { ...activeState } 
            menuState.home = false;
            menuState.news = false; 
            menuState.folder = false;
            menuState.bag = false;
            menuState.search = false;
            menuState.cart = false;
            menuState.trx = true;
            setActiveState(menuState);
        }
    }

    const onSearchEnter = (e)=>{
        if(e.nativeEvent.key == "Enter"){
            let keyword = inputKeyword.current.value; 
            if(keyword != ""){
                if(isMounted()){
                    let menuState = { ...activeState}
                    menuState.home = false;
                    menuState.news = false; 
                    menuState.folder = false;
                    menuState.bag = false;

                    if(!menuState.search){
                        menuState.search = true;
                        setActiveState(menuState); 
                    } 
                    setSearchKeyword(keyword);
                }
            }
        }
    }

    useEffect(()=>{
        _is_mounted = true;
        //console.log(JSON.stringify(clientParams)); 
        if(searchKeyword != "" && activeState.search){
            searchRef.current.startSearch();
        }
        return ()=>{
            //console.log("IS MOUNTED FALSE");
            _is_mounted = false;
        }
    },[searchKeyword, searchType, activeState.search])

    useEffect(()=>{
        listTroli()
        pendingTransaction(); 
        window.addEventListener("message", forceLogout); 
        return ()=>{
            window.removeEventListener("message", forceLogout)
        }
    },[])

    useEffect(()=>{
        if(firstRun){
            setFirstRun(false); 
            //Set Screen Name 
            window.electron.sendMessage("SCREEN_NAME", {screen_name : "Main"}); 
            //ANCHOR - Check apakah mengandung id_konten 
            window.electron.receiveMessage("DIRECT_READ", (params)=>{
                let id_konten = params.id_konten; 
                directRead(id_konten); 
            })
            //ANCHOR - Read Token ketika aplikas dalam keadaan terbuka dan sudah login
            window.electron.receiveMessage("RECEIVE_READ_TOKEN", (params)=>{
                let token = params.token; 
                getIdContent(token); 
            })
        }
    },[firstRun])

    //ANCHOR - Mendapatkan id_konten dari read token 
    const getIdContent = async(token)=>{
        const url = api.verifyAppToken + token; 
        const response = await executeGet(url); 
        if(response.code == 200){
            let id_konten = response.data.id_konten; 
            directRead(id_konten);
        }
    }
    //ANCHOR - CHECK TROLI 
    const listTroli = async()=>{
        let formData = new FormData(); 
        formData.append("page", 1);
        formData.append("limit", 100);
        const response = await executePost(api.listTroli, formData);
        if(response.code == resCode.ok){
            //console.log("LIST TROLI");
            //console.log(JSON.stringify(response)); 
            let _count = response.count; 
            setCartCount(_count); 
        }
    }
    //ANCHOR - PENDING TRANSACTION 
    const pendingTransaction = async()=>{
        const response = await executeGet(api.pendingTransactionCount); 
        //console.log(JSON.stringify(response)); 
        if(response.code == resCode.ok){
            setPendingTrx(response.total);
        }
    }

    //ANCHOR - Request Logout
    const requestLogout = async()=>{
        const response = await executePost(api.logout, new FormData()); 
        //console.log(JSON.stringify(response));
        if(response.code == resCode.ok){
            setShowProgressDialog(false); 
            handleLogout();
        } else {
            setShowProgressDialog(false); 
        }
    }
    //ANCHOR - Force Logout 
    const forceLogout = (evt)=>{
        //TODO: Validate Origin
        if(evt.data.command == "FORCE_LOGOUT"){
            handleLogout(); 
        }   
    }
    //ANCHOR - Handle Logout
    const handleLogout = async()=>{
        let versionCode = clientParams.kode_versi; 
        let clientCode = clientParams.kode; 
        let uuid = global.headers.UUID; 
        let os = global.headers.OS;
        try {
            await AsyncStorage.removeItem(clientCode); 
            createHeaders({
                code : clientCode,
                os : os,  
                version : versionCode, 
                clientId : clientParams.id,  
                uuid : uuid
            });

            if(clientParams.id == 6917) {
                const logoutSessionURL = "https://iam.ub.ac.id/auth/realms/ub/protocol/openid-connect/logout?state="+uuid+"&client_id=https%3A%2F%2Fwww.kubuku.co.id%2F&redirect_uri=https%3A%2F%2Fwww.kubuku.co.id%2Fkbk2648856";
                window.electron.sendMessage("LOGOUT_SSO", { url : logoutSessionURL});

                props.navigation.reset({
                    index : 0, 
                    routes : [{ name : "LoginUnibraw"}]
                })
            } else {
                props.navigation.reset({
                    index : 0, 
                    routes :[{ name : "Login"}]
                })
            }
        } catch(error){
            console.error(error.message); 
        }
    }
    //ANCHOR - Direct Read 
    const directRead = async(id)=>{
        let formData = new FormData();
        formData.append("id_konten", id); 
        const response = await executePost(api.detailContent, formData); 
        if(response.code == resCode.ok){
            let bookData = response.data; 
            const readResponse = await executePost(api.requestBaca, formData); 
            if(readResponse.code == resCode.ok){
                let data = {
                    book : bookData, 
                    read : readResponse, 
                    readMode : true 
                }
                props.navigation.navigate("Reader", data); 
            }
        }
    }

    //ANCHOR - Handle Change Avatar 
    const handleChangeAvatar = async()=>{
        setAnchorEl(null);
        if(!isUploadingAvatarRef.current){
            const document = await DocumentPicker.getDocumentAsync({
                type : "image/*"
            }); 

            if(document.type == "success") {
                //console.log("Success"); 
                isUploadingAvatarRef.current = true; 
                let filename = document.name; 
                let mimeType = document.mimeType; 
                let fileUri = document.uri; 
                let fileObj = dataURLToFile(fileUri , filename); 
                let formData = new FormData(); 
                formData.append("photo",fileObj); 
                const response = await executePost(api.updateAvatar, formData); 
                //console.log(JSON.stringify(response)); 
                if(response.code == resCode.ok){
                    let url_foto = response.url; 
                    let user_info = { ...userInfo} 
                    user_info.url_foto = url_foto;
                    dispatch(setUserParams(user_info)); 
                } 
                isUploadingAvatarRef.current = false; 
            }
        }
    }
    //ANCHOR - Handle Book Request
    const handleBookRequestOrder = ()=>{
        setAnchorEl(null);
        setOpenBookRequestDialog(true);
    }

    //ANCHOR - Submit Book Request 
    const handleSubmitBookRequest = async(params)=>{
        setOpenBookRequestDialog(false); 
        let formData = new FormData(); 
        formData.append("judul", params.title); 
        formData.append("penulis", params.author); 
        formData.append("penerbit", params.publisher); 
        formData.append("tahun", params.year);
        formData.append("isbn", params.isbn); 

        const response = await executePost(api.submitBookRequest, formData); 
        if(response.code == resCode.ok ){
            setShowSuccessDialog({ show : true, message : "Permintaan berhasil terkirim!"}); 
        }
    }


    //ANCHOR - MAIN RENDER
    return(
        <ThemeProvider theme={theme}>
            <View style={{
                flex : 1, 
            
            }}>
                <View style={{
                    flexDirection : "row",
                    height : "100%"
                }}>
                
                    <Paper
                        elevation={2}
                        style={{ 
                            flex : 0.2,
                            marginTop : 8, 
                            marginLeft : 8,
                            marginRight : 4,
                            marginBottom : 8,
                            borderRadius : 8
                        }}
                    >
                    
                        <img
                            src={ clientParams.url_logo}
                            style={{
                                height : 40, 
                                width : "auto",
                                marginLeft : 16,
                                marginTop : 16,
                                marginBottom : 16
                            }}
                        />
                        {
                            //ANCHOR - Side Menu Icons 
                        }

                        <ButtonHome
                            color={clientParams.warna_header}
                            active={ activeState.home }
                            hovered={ homeHovered }
                            onMouseEnter={ onHomeEnter }
                            onMouseLeave={ onHomeLeave }
                            onClick={()=>{ onMenuClick("home")}}
                        />
                        <ButtonNews
                            style={{marginTop : 16 }}
                            color={clientParams.warna_header}
                            active={ activeState.news }
                            hovered={ newsHovered }
                            onMouseEnter={ onNewsEnter }
                            onMouseLeave={ onNewsLeave }
                            onClick={()=>{ onMenuClick("news")}}
                        />
                        <ButtonRack
                            style={{marginTop : 16 }}
                            color={clientParams.warna_header}
                            active={ activeState.folder }
                            hovered={ folderHovered }
                            onMouseEnter={ onFolderEnter }
                            onMouseLeave={ onFolderLeave }
                            onClick={()=>{ onMenuClick("folder")}}
                        />
                        {
                            //ANCHOR - MENU DONASI
                            clientParams.fitur_donasi == "1" && 
                            <>
                                <ButtonBag
                                    style={{marginTop : 16 }}
                                    color={clientParams.warna_header}
                                    active={ activeState.bag }
                                    hovered={ bagHovered }
                                    onMouseEnter={ onBagEnter }
                                    onMouseLeave={ onBagLeave }
                                    onClick={()=>{ onMenuClick("bag")}}
                                />
                                <ButtonCart
                                    style={{marginTop : 16 }}
                                    color={clientParams.warna_header}
                                    active={ activeState.cart }
                                    hovered={ cartHovered }
                                    onMouseEnter={ onCartEnter }
                                    onMouseLeave={ onCartLeave }
                                    onClick={()=>{ onMenuClick("cart")}}
                                    count={ cartCount }

                                />
                                <ButtonTransaction
                                    style={{marginTop : 16 }}
                                    color={clientParams.warna_header}
                                    active={ activeState.trx }
                                    hovered={ trxHovered }
                                    onMouseEnter={ onTrxEnter }
                                    onMouseLeave={ onTrxLeave }
                                    onClick={()=>{ onMenuClick("trx")}}
                                    count={ pendingTrx }
                                />
                            </>
                        }
                    </Paper>
                    <Paper
                        elevation={2}
                        style={{
                            flex : 0.8,
                            marginTop : 8, 
                            marginRight : 8,
                            marginLeft : 4,
                            marginBottom : 8,
                            borderRadius : 8
                        }}
                    >
                    
                        <View style={{height : "100%"}}>
                            <View style={ styles.toolbar}>
                                <View >
                                    <TextField 
                                        ref={ inputKeyword }
                                        withLabel={ false }
                                        borderColor={"silver"}
                                        placeholder={"Pencarian"}
                                        withIcon={true}
                                        icon={<SearchIcon sx={{width : 18, height : 18 }} />}
                                        marginLeft={16}
                                        marginRight={16}
                                        fontSize={12}
                                        maxWidth={350}
                                        maxHeight={32}
                                        padding={0}
                                        onChange={(text)=>{
                                            
                                        }}
                                        onKeyPress={ onSearchEnter }
                                    />
                                    
                                </View>
                                <RadioGroup 
                                    style={{flex : 1, alignItems : 'center'}}
                                    row
                                    onChange={(event)=>{
                                        setSearchType(event.target.value);
                                    }}
                                >
                                    <Radio 
                                        checked={ searchType == "umum" }
                                        value={"umum"}
                                        size={"small"}
                                    />
                                    <Label
                                        size={12}
                                        text={"Pencarian Umum"}
                                        weight={"400"}
                                    />
                                    <Radio 
                                        checked={ searchType == "kalimat" }
                                        value={"kalimat"}
                                        size={"small"}
                                    />
                                    <Label
                                        size={12}
                                        text={"Pencarian Kalimat"}
                                        weight={"400"}
                                    />
                                    
                                </RadioGroup>

                                <View style={ styles.avatarContainer }>
                                    <View style={ styles.usernameContainer }>
                                        <Label
                                            size={14}
                                            text={userInfo.nama}
                                            weight={"600"}
                                        />
                                        <Label
                                            size={12}
                                            text={"Logout"}
                                            weight={"600"}
                                            color={ clientParams.warna_header }
                                            style={{
                                                borderRadius : 4, 
                                                padding : 2, 
                                                borderWidth : 1, 
                                                borderColor : clientParams.warna_header,
                                                cursor : "pointer"
                                            }}
                                            onClick={ optLogout }
                                        />
                                    </View>
                                    <Avatar 
                                        src={ userInfo.url_foto }
                                        sx={{
                                            width : 42, 
                                            height : 42,
                                            cursor : "pointer"
                                        }}
                                        onClick={(event)=>{
                                            setAnchorEl(event.currentTarget); 
                                        }}
                                    />
                                    {
                                        //ANCHOR - Notification Container
                                    }
                                    {
                                        notificationAvailable && 
                                        <View style={ styles.notificationContainer}>
                                            <Label
                                                size={8}
                                                color={"white"}
                                                weight={"600"}
                                                text={"1"}
                                            />
                                        </View>
                                    }
                                    
                                    
                                </View>
                            </View>
                            <View style={{
                                position : "absolute", 
                                top : 64, 
                                bottom :0, 
                                left : 0, 
                                right : 0
                            }}>
                                {
                                    activeState.home && 
                                    <Home 
                                        data={ homeData }
                                        callback={(params)=>{
                                            setHomedata(params); 
                                        }}
                                        navigation={props.navigation}
                                    />
                                }
                                {
                                    activeState.news && 
                                    <News 
                                        data={newsData }
                                        callback={(params)=>{
                                            setNewsData(params); 
                                        }}
                                        navigation={props.navigation}
                                    />
                                }
                                {
                                    activeState.folder && 
                                    <BookRack 
                                        data={rackdata}
                                        callback={(params)=>{
                                            setRackData(params); 
                                        }}
                                        navigation={props.navigation}
                                    />
                                }  
                                {
                                    activeState.bag && 
                                    <DonationStore 
                                        navigation={props.navigation}
                                        onAddCart={(total)=>{
                                            setCartCount(total); 
                                        }}
                                    />
                                } 
                                {
                                    activeState.cart && 
                                    <DonationCart
                                        navigation={props.navigation}
                                        onUpdateCart={(total)=>{
                                            setCartCount(total)
                                        }}
                                    />
                                }
                                {
                                    activeState.trx && 
                                    <TransactionList 
                                        navigation={props.navigation}
                                        onUpdateTrx={(total)=>{
                                            setPendingTrx(total)
                                        }}
                                    />
                                }

                                {
                                    activeState.search && 
                                    <SearchPage 
                                        ref={ searchRef }
                                        keyword={ searchKeyword }
                                        type={ searchType }
                                        onBackClick={()=>{
                                            onMenuClick("home");
                                        }}
                                        navigation={props.navigation}
                                    />
                                }
                            </View>
                        </View>
                        
                    </Paper>
                </View>
                {
                    //ANCHOR - 01. Dialog To Confirm Logout
                }
                <ConfirmDialog 
                    open={ showConfirmLogout }
                    message={"Keluar dari akun ini?"}
                    labelPositive={"Ya"}
                    labelNegative={"Tidak"} 
                    onPositiveClick={()=>{
                        setShowConfirmLogout(false); 
                        setShowProgressDialog(true); 
                        requestLogout();    
                    }}
                    onNegativeClick={()=>{
                        setShowConfirmLogout(false)
                    }}
                />
                <ProgressDialog 
                    open={ showProgressDialog } 
                    message={"Keluar dari akun anda"}
                />
                {
                    //ANCHOR - Popover Menu Avatar
                }
                <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={()=>{
                        setAnchorEl(null)
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleChangeAvatar}>
                                <ListItemIcon>
                                    <AccountCircleOutlinedIcon />
                                </ListItemIcon>
                                <Label 
                                    size={14}
                                    text={"Ganti foto profil"}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={ handleBookRequestOrder }>
                                <ListItemIcon>
                                    <ListAltOutlinedIcon/>
                                </ListItemIcon>
                                <Label 
                                    size={14}
                                    text={"Permintaan buku"}
                                />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Popover>
                {
                    //ANCHOR - Book Request Dialog
                }
                <BookRequestDialog 
                    open={ openBookRequestDialog}
                    onCloseClick={()=>{
                        setOpenBookRequestDialog(false)
                    }}
                    width={450}
                    height={600}
                    onSubmit={ handleSubmitBookRequest }
                />
                {
                    //ANCHOR - Success Dialog 
                }
                <SuccessDialog 
                    open={showSuccessDialog.show}
                    message={showSuccessDialog.message}
                    label={"OK"}
                    onClick={()=>{
                        setShowSuccessDialog({
                            show : false, 
                            message : ""
                        })
                    }}
                />
            </View>
        </ThemeProvider>

    )
}


const styles = StyleSheet.create({
    toolbar : {
        height : 64,
        flexDirection : "row", 
        alignItems : "center",
        tabFocus : -1
    },
    logoContainer : {
        flexDirection : "row", 
        alignItems : "center",
        flex : 1
    },
    avatarContainer :{
        flexDirection : "row", 
        alignItems : "center",
        marginRight : 16
    },
    usernameContainer : {
        marginRight : 8,
        alignItems : "flex-end"
    },
    notificationContainer : {
        width : 16, 
        height : 16, 
        position : "absolute", 
        top : 0, 
        right : -4,
        alignItems : "center", 
        justifyContent : "center", 
        borderRadius : 24, 
        backgroundColor : "#e74c3c",

    }
})
export { Main }